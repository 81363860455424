$cta-card-fc-pad: 1rem 2rem 2rem 2rem;
$cta-card-img-ratio: 62.5%;

.user_mode .pageEl.cta-card, .user_mode .pageEl.text-card {
  figure {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    &:hover .photo {
      background-size: 105% auto;
    }
    .photo {
      background-position: center;
      background-size: 100% auto;
      height: 0;
      padding-bottom: 62.5%;
      transition: background-size $transition-ease;
    }
  }
  figcaption {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    padding: $cta-card-fc-pad;
    h4 {
      text-align: center;
      padding-bottom: 1.5rem;
    }
    div {
      font-size: 1.6rem;
      color: black;
      &:not(.btn) > a {
        text-align: center;
        font-weight: 700;
        &:hover{
          text-decoration: underline;
        }
      }

      &.btn {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
        padding: 2rem 2rem 1rem 2rem;
        a {
          display: flex;
          color: white;
          text-transform: uppercase;
          font-weight: 700;
          background-color: $color-primary;
          border: 0;
          border-radius: 0.3rem;
          padding: 1rem 1.5rem;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          transition: transform $transition-ease, box-shadow $transition-ease;
          &:hover {
            //transform: scale(1.02);
            box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}
