// body.edit_mode {
//   .pageElement {
//       font-size: 10px;
//   }
//   p {
//       font-size: 10px;
//   }
// }
// .layoutContainerAdmin {
//     ul {
//         padding-left: 20px;
//     }
// }

.edit_mode #lightbox {
  width: 1400px;
  max-width: 95%;

  #lightboxHeader {
    h1,
    a {
      background-size: cover;
    }
  }
  #lightboxFooter #lightboxFeedback {
    background-size: cover;
  }

  .fcForm {
    & > table {
      max-width: 95%;
    }
    input#layout_container_custom_css_class {
      width: 100%;
    }
    input#custom_css_class {
      width: 100%;
    }
    &.textarea {
      width: 100%;
    }
    textarea#form_values_content {
      width: 100%;
    }
    textarea {
      width: 100%;
    }
  }
}

#lightboxBody {
  .tableBuilder {
    margin: 0 auto;
    width: 85%;
    .buildLinks {
      td {
        &:first-child {
          max-width: 55px;
        }
        &:nth-child(n + 1) {
          width: auto;
        }
      }
    }
    input {
      width: 100%;
    }
  }
}

.edit_mode {
  a {
    &.lbOn,
    &.collapsePE,
    &.expandPE,
    &.buttonLink {
      color: #333;
    }
  }

  .siteMapElement, .containerBar {
    a {
      color: #333;
    }
  }

  .layoutContainerAdmin .columnBounds {
    height: 100%;
  }
  .json-data-source {
    border: 1px solid red;
  }
  .json-data-source:before {
    content: 'Edit table to view data';
    padding: 1rem;
  }
  #permission_trees{
    h3{
      font-size: 2rem;
    }
  }
}
