// Page Layouts
// 1. Layout Containers, Columns, and Rows
// 2. Edit Mode
// 3. Page Title (display Body Header)
// The following can be removed if we had a standard layout.
// 5. News Article Page
// 6. Month List and Day View (Calendar)
// 7. Single Event Page
// 8. Sports Pages (Roster, Game Schedule, Player Stats, Team Stats, Photo, Video)
// 9. Error Notices

// .user_mode #panelTwo{
//   background-color: $color-white;
// }

// 1. Layout Containers, Columns, and Rows
.layoutContainer {
  background-color: $content-area-background;
  //@include content-area-width;
  //@include content-container-padding;
  @include content-container-padding-vert;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  @include content-width();
  // padding-left: calc((100% - #{$content-area-width}) / 2);
  // padding-right: calc((100% - #{$content-area-width}) / 2);
  position: relative;

  .layoutContainer {
    // This targets Tab Element layoutContainers
    @include content-container-padding(0, false);
    padding-bottom: 15px; // refactor when doing tab element
    .user_mode & {
      @include adjust-margin-x(-1);
      width: auto;
    }
  }
}
.user_mode .layoutContainer {
  &.bg-color-primary {
    background-color: var(--color-primary);
  }
  &.bg-color-secondary {
    background-color: var(--color-secondary);
  }
  &.bg-color-transparent {
    background-color: transparent;
  }
  &.bg-color-gray-lt {
    background-color: #f1f1f1;
  }
  &.bg-color-gray-md {
    background-color: #ccc;
  }
  &.bg-color-gray {
    background-color: #555a5c;
  }
  &.bg-color-gray-dk {
    background-color: #1f1f1f;
  }
  &.bg-color-black {
    background-color: $color-black;
  }
  &.bg-color-white {
    background-color: #fff;
  }

  &.text-color-light {
    color: white;
    .pageElement:not(.linkElement) {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      a {
        color: white;
      }
    }
  }
  &.text-color-dark {
    color: $color-titles;
    .pageElement:not(.linkElement) {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      a {
        color: $color-titles;
      }
    }
  }
  &.min-height {
    min-height: 45.6rem;
    align-items: center;
    @media (min-width: $breakpoint-lg-max) {
      min-height: 615px;
    }
  }
  &.add-overlay {
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
    }
  }
  &.bg-image-repeat {
    background-repeat: repeat;
  }
  &.bg-image-repeat-x {
    background-repeat: repeat-x;
  }
  &.bg-image-repeat-y {
    background-repeat: repeat-y;
  }
  &.bg-image-parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.image-bg {
    background-position: center;
  }
  &.bg-image-align-right {
    background-position: right center;
  }
  &.bg-image-align-left {
    background-position: left center;
  }
  &.bg-image-multiply {
    background-blend-mode: multiply;
  }
  &.flex-col-sm {
    .column {
      display: flex;
      flex-direction: column;
    }
  }
  &.flex-col-md {
    .column {
      @media (min-width: $breakpoint-md-min) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &.flex-col-lg {
    .column {
      @media (min-width: $breakpoint-lg-max) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &.flex-row-sm {
    .column {
      display: flex;
      flex-direction: row;
    }
  }
  &.flex-row-md {
    .column {
      @media (min-width: $breakpoint-md-min) {
        display: flex;
        flex-direction: row;
      }
    }
  }
  &.flex-row-lg {
    .column {
      @media (min-width: $breakpoint-lg-max) {
        display: flex;
        flex-direction: row;
      }
    }
  }
  &.flex-jus-ctr-sm {
    .column {
      justify-content: center;
    }
  }
  &.flex-jus-ctr-md {
    .column {
      @media (min-width: $breakpoint-md-min) {
        justify-content: center;
      }
    }
  }
  &.flex-jus-ctr-lg {
    .column {
      @media (min-width: $breakpoint-lg-max) {
        justify-content: center;
      }
    }
  }
  &.flex-jus-btw-sm {
    .column {
      justify-content: space-between;
    }
  }
  &.flex-jus-btw-md {
    .column {
      @media (min-width: $breakpoint-md-min) {
        justify-content: space-between;
      }
    }
  }
  &.flex-jus-btw-lg {
    .column {
      @media (min-width: $breakpoint-lg-max) {
        justify-content: space-between;
      }
    }
  }
  &.align-ctr {
    .column {
      align-items: center;
    }
  }
  &.el-pad-x {
    .pageElement {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &.ct-pad-y-sm {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
  &.ct-pad-y-md {
    padding-top: 2rem;
    padding-bottom: 2rem;
    @media (min-width: $breakpoint-lg-max) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  &.ct-pad-y-lg {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media (min-width: $breakpoint-lg-max) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
  &.ct-pad-y-xlg {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media (min-width: $breakpoint-lg-max) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }
  &.ct-pad-y-xxlg {
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media (min-width: $breakpoint-lg-max) {
      padding-top: 9rem;
      padding-bottom: 8rem;
    }
  }
  &.ct-pad-y-collapse-top {
    padding-top: 0;
    @media (min-width: $breakpoint-lg-max) {
      padding-top: 0;
    }
  }
}
.full-width-container {
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.full-width {
  max-width: 100%;
  padding: 0;
  > .column {
    max-width: 100%;
  }

  &.collapse-padding {
    padding-left: 0;
    padding-right: 0;
    > .column {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.column {
  position: relative;
  min-height: 1px;
  @include column-padding;
  @media screen and (max-width: $breakpoint-sm-max) {
    width: 100%;
  }
  .edit_mode & {
    padding-left: 0;
    padding-right: 0;
  }
}
.row {
  @extend %clearfix;
  > .column {
    float: left;
  }
}
@media screen and (max-width: $breakpoint-sm-max) {
  .col {
    &-xs {
      &-12 {
        width: 100%;
      }
      &-11 {
        width: (11/12 * 100%);
      }
      &-10 {
        width: (10/12 * 100%);
      }
      &-9 {
        width: (9/12 * 100%);
      }
      &-8 {
        width: (8/12 * 100%);
      }
      &-7 {
        width: (7/12 * 100%);
      }
      &-6 {
        width: (6/12 * 100%);
      }
      &-5 {
        width: (5/12 * 100%);
      }
      &-4 {
        width: (4/12 * 100%);
      }
      &-3 {
        width: (3/12 * 100%);
      }
      &-2 {
        width: (2/12 * 100%);
      }
      &-1 {
        width: (1/12 * 100%);
      }
    }
  }
}
@media screen and (min-width: $breakpoint-md-min) {
  .col-md {
    &-12 {
      width: 100%;
    }
    &-11 {
      width: (11/12 * 100%);
    }
    &-10 {
      width: (10/12 * 100%);
    }
    &-9 {
      width: (9/12 * 100%);
    }
    &-8 {
      width: (8/12 * 100%);
    }
    &-7 {
      width: (7/12 * 100%);
    }
    &-6 {
      width: (6/12 * 100%);
    }
    &-5 {
      width: (5/12 * 100%);
    }
    &-4 {
      width: (4/12 * 100%);
    }
    &-3 {
      width: (3/12 * 100%);
    }
    &-2 {
      width: (2/12 * 100%);
    }
    &-1 {
      width: (1/12 * 100%);
    }
  }
  .full-width-container {
    .col-md {
      &-12 {
        max-width: (($content-area-width - $content-area-padding) * (12/12));
      }
      &-11 {
        max-width: (($content-area-width - $content-area-padding) * (11/12));
      }
      &-10 {
        max-width: (($content-area-width - $content-area-padding) * (10/12));
      }
      &-9 {
        max-width: (($content-area-width - $content-area-padding) * (9/12));
      }
      &-8 {
        max-width: (($content-area-width - $content-area-padding) * (8/12));
      }
      &-7 {
        max-width: (($content-area-width - $content-area-padding) * (7/12));
      }
      &-6 {
        max-width: (($content-area-width - $content-area-padding) * (6/12));
      }
      &-5 {
        max-width: (($content-area-width - $content-area-padding) * (5/12));
      }
      &-4 {
        max-width: (($content-area-width - $content-area-padding) * (4/12));
      }
      &-3 {
        max-width: (($content-area-width - $content-area-padding) * (3/12));
      }
      &-2 {
        max-width: (($content-area-width - $content-area-padding) * (2/12));
      }
      &-1 {
        max-width: (($content-area-width - $content-area-padding) * (1/12));
      }
    }
  }
}
@media screen and (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
  .layout-50-25-25,
  .layout-25-25-50 {
    .col-md-3 {
      width: (6/12 * 100%);
      max-width: (($content-area-width - $content-area-padding) * (6/12));
    }
    .col-md-6 {
      width: 100%;
      max-width: (($content-area-width - $content-area-padding) * (12/12));
    }
  }
  .layout-25-50-25 {
    .col-md-3,
    .col-md-6 {
      width: (4/12 * 100%);
      max-width: (($content-area-width - $content-area-padding) * (4/12));
    }
  }
}

// 2. Edit Mode
.edit_mode .yieldPageContent {
  font-size: 1.4rem;
  @include content-area-width;
}

// 3. Page Title (display Body Header)
#displayBodyHeader {
  // @include page-element-padding;
  // @include content-area-width;
  // @include content-container-padding(2);
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  background-color: $content-area-background;
  max-width: 100%;
  @include content-width();
  //padding-left: calc((100% - #{$content-area-width}) / 2);
  //padding-right: calc((100% - #{$content-area-width}) / 2);
  position: relative;
}

// 5. News Article Page
.newsItemColumn {
  background-color: $content-area-background;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  @include content-width();
  //padding-left: calc((100% - #{$content-area-width}) / 2);
  //padding-right: calc((100% - #{$content-area-width}) / 2);
  position: relative;
  .column:first-of-type {
    width: 100%;
  }
}
.newsContentNode {
  + .column {
    background-color: $content-area-background;
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    @include content-width();
    // padding-left: calc((100% - #{$content-area-width}) / 2);
    // padding-right: calc((100% - #{$content-area-width}) / 2);
    position: relative;
    //@include content-area-width;
    //@include content-container-padding(2);
  }
}
// News Article Page - Draft
.yieldPageContent > .noEntry.pageElement {
  @include content-area-width;
  @include content-container-padding(2);
}

// 6. Month List and Day View (Calendar)
.calendarContainer {
  background-color: $content-area-background;
  @include content-area-width;
  @include content-container-padding(2);
}

// 7. Single Event Page
#event_show,
#event_show + .eventTags,
#event_show + .eventTags + .bottomOptions {
  background-color: $content-area-background;
  width: 100%;
  //@include content-area-width;
  @include content-width();
  //@include content-container-padding(2);
  .pageOptions {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// 8. Sports Pages (Roster, Game Schedule, Player Stats, Team Stats, Photo, Video)
#sport-header {
  background-color: $content-area-background;
  @include content-area-width;
  @include content-container-padding(2);
}
.user_mode {
  .leaguePage,
  .sportsPage,
  [id='sport-header'] ~ [id='panelTwo'] {
    .yieldPageContent {
      font-size: 1.4rem;
      > div,
      > ul,
      ~ #rapidTabs,
      ~ #contentTabsLoader,
      ~ #tab_completegamelist_content,
      ~ #tab_boxscores_content {
        background-color: $content-area-background;
        @include content-area-width;
        @include content-container-padding(2);
      }
    }
  }
}

// 9. Error Notices
#errorNotice {
  background-color: $content-area-background;
  @include content-area-width;
  @include content-container-padding(2);
}
