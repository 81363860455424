/* News Article View */
// 1. News Article
// 2. Page layout padding/structure
// 3. Tags

// 1. News Article
.newsItemElement {
  .titleText {
    margin-bottom: 1.5rem;
  }
  .authorDetails {
    font-size: 1.2rem;
    margin: 15px 0;
  }
  .teaserText, h4.teaserText {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 1rem;
    color: $color-secondary;
  }
  .imageOriginal {
    display: block;
    width: 100%;
    border: $borderWidth solid $borderColor;
    margin-bottom: 1rem;
  }
  .newsArticleThumbnail {
    float: left;
    margin: 0.35em 1em 0 0;
    border: $borderWidth solid $borderColor;
    height: 4em; // Double the "titleText" font-size
  }
}

// 2. Page layout padding/structure
// .newsPage .yieldPageContent{
//   padding-top: $desktop-padding * 2 !important;
// }
// @media only screen and (max-width: 768px){
//   .newsPage .yieldPageContent{
//     padding-top: $mobile-padding * 2 !important;
//   }
// }

// 3. Tags
.newsTags {
  font-size: 1.4rem;
  @include column-padding;
}

.newsPage {
  .bottomOptions {
    width: 100%;
    max-width: 100%;
    @include column-padding;
  }
}

.user_mode {
  &.newsPage {
    .article-backlink{
      background: black;
      color: $color-primary;
      padding: 20px;
      text-align: left;
      a {
        color: $color-primary;
        text-decoration: none;
        &:hover {
          color:white;
        }
        &:before{
          content: '\f104';
          font-family: 'FontAwesome';
          padding-right: 10px;
        }

      }
    }
    h2.titleText {
      font-size: 2.5rem;
    }
    h4.teaserText {
      font-size: 1.5rem;
    }
    #topNav {
      background-color: black;
      top: 0;
      display: none;
    }
    .newsArticleThumbnail {
      display: none !important;
    }
    @media (min-width: $breakpoint-xlg-min) {
      #siteContainer {
        margin-top: 0;
      }
    }
  }
}
