.pager-controls {
  display: flex;
  justify-content: center;
  gap: 7px;
  width: 100%;
  padding: 0 20px 20px 20px;
  &.hidden{
    display:none;
  }
}
.pager-btn {
  flex-grow: 1;
  @media (min-width: $breakpoint-md-min) {
    max-width: 75px;
  }
  @media (min-width: $breakpoint-xlg-min) {
    min-width: 35px;
    max-width: 35px;
  }
  color: white;
  background: #aaa;
  padding: 5px 7px;
  cursor: pointer;

  min-height: 35px;
  display: grid;
  place-items: center;
  transition: background-color $transition-linear;
  cursor: pointer;
  &.next,
  &.prev {
    font-family: 'fontawesome';
    font-size: 1.8rem;
  }
  &.active {
    background-color: #222;
  }
  &.disabled {
    opacity: .4;
    pointer-events: none;
  }
  &:hover {
    background-color: #222;
    @media (hover: none) and (pointer: coarse) {
      background: #aaa;
    }
  }
}
