.user_mode {
  section {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  .accordion {
    overflow: hidden;
    border: 1px solid #eee;
    margin: 10px 0;
    padding: 0 15px;

    h3:first-of-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      line-height: 1.25;
      margin: 0;
      padding: 0;
      cursor: pointer;
      &:after {
        content: '\f107';
        font-family: fontawesome;
        display: grid;
        place-items:center;
        padding: 0;
        height: 35px;
        width: 35px;
        line-height: 35px;
        transition: transform $transition-ease;
      }
    }

    &.active {
      h3:after {
        transform: rotate(180deg);
      }
    }
    .text {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-in-out;
    }
  }
}
