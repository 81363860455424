.user_mode .layoutContainer.legends-cards-container {
  .column-1 {
    display: grid;

    @media (min-width: $breakpoint-md-min) {
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 1fr;
    }
    @media (min-width: $breakpoint-lg-max) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1280px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    gap: 1.5rem;
    //background: $color-white;
    padding: 0 0 3rem 0;
    // display: flex;
    // gap: 1.5rem;
    // justify-content: center;
    // flex-flow: row wrap;

    .legend-card {
      //max-width: calc(25% - 1.5rem);
      display: inline-block;
      position: relative;
      &.scroll {
        .legend-info,
        .legend-rank,
        .legend-flag {
          transform: translateY(-90px);
        }
        .legend-info {
          max-height: 300px;
          overflow-y: auto;
        }
        .legend-info .legend-events-list li:nth-child(n + 4) {
          opacity: 1;
        }
      }
      &.hidden {
        display: none;
      }
    }

    .legend-card-inner {
      position: relative;
      background-color: white;

      max-width: 100%;
      min-width: 300px;
      min-height: 380px;
      margin: auto;
      border-radius: 10px;
      overflow: hidden;
      @media (min-width: $breakpoint-md-min) {
        aspect-ratio: 300 / 420;
      }
      .legend-image {
        aspect-ratio: 4/3;
        overflow: hidden;
        position: relative;
      }
      .legend-photo {
        max-width: 100%;
      }
      .legend-rank {
        position: absolute;
        bottom: 15px;
        left: 15px;
        z-index: 1;
        color: white;
        font-size: 36px;
        font-weight: 700;
        line-height: 1;
        transition: transform 0.5s ease;
        span {
          font-size: 16px;
        }
      }
      .legend-flag {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: white;
        line-height: 1;
        position: absolute;
        aspect-ratio: 3 / 2;
        height: 41px;
        bottom: 15px;
        right: 15px;
        transition: transform 0.5s ease;
        z-index: 1;
      }
      .legend-info {
        background-color: white;
        padding: 15px;
        @media (min-width: $breakpoint-md-min) {
          position: absolute;
        }
        top: calc(224 / 420 * 100%);
        left: 0;
        right: 0;
        z-index: 2;
        transition: transform 0.5s ease;
      }
      .legend-name {
        font-size: 18px;
        line-height: 1;
        padding: 0;
        margin: 0;
      }
      .legend-events-list {
        list-style-type: none;
        padding: 5px 0;
        margin: 0;
        font-size: 12px;

        li {
          padding: 8px 0;
          border-bottom: 1px solid #ddd;
          line-height: 1.3;
          &:last-child {
            border: 0;
          }
        }
        @media (min-width: $breakpoint-md-min) {
          li:nth-child(n + 4) {
            transition: opacity $transition-linear;
            opacity: 0;
          }
        }

        .event-name {
          font-weight: 700;
        }
      }
      .legend-link {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
    .event-list-expand {
      display: none;
      @media (min-width: $breakpoint-md-min) {
        display: flex;
      }
      font-family: 'fontawesome';
      position: absolute;
      aspect-ratio: 1/1;
      line-height: 1;

      justify-content: center;
      align-items: center;
      width: 20px;
      bottom: -10px;
      right: 30px;
      background: #555;
      font-size: 12px;
      color: white;
      border: 3px solid white;
      border-radius: 50%;
      overflow: hidden;
      z-index: 3;
      transition: background-color $transition-linear;
      &:hover {
        background-color: #888;
      }
    }
  }
}
