.user_mode div.hero-video {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  aspect-ratio: 21/9;
  min-height: 42rem;

  .hero-video-overlay {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 15%,
      rgba(0, 0, 0, 0) 85%,
      rgba(0, 0, 0, 0.6) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:1;

    .hero-video-overlay-content {
      position: absolute;
      left: 10%;
      right: 10%;
      bottom: 5%;
      max-width: 65rem;
      

      h3,
      .text,
      .text p {
        font-size: unquote('clamp(2rem, 5vw + 1rem, 4.2rem)');
        line-height: 1.15;
        font-weight: 700;
        color: white;
        text-align: left;
      }
      .text,
      .text p {
        font-size: 1.8rem;
        font-weight: 400;
        padding-top: 1.5rem;
      }
    }
  }

  video {
    pointer-events: none;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
  }
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    transform: scale(1.02);
  }

  @media screen and (min-width: 1024px) {
    .hero-video-overlay .hero-video-overlay-content {
      bottom: 8%;
    }
  }
  @media screen and (max-width: 1023px) {
    &.has-bg-image {
      video {
        display: none;
      }
      iframe {
        display: none;
      }
    }
  }
}
