.user_mode {
  .news-cards-slider {
    overflow: hidden;
    padding: 0;
    @media(min-width: 1400px){
      padding-left: calc((100% - 1240px) / 2);
      padding-right: calc((100% - 1240px) / 2);
    }
    .column {
      padding: 0;
    }
    .news-cards {
      .collectorNews,
      .newsAggregatorElement {
        display: block;
        padding: 0;
        @media (min-width: $breakpoint-md-min) {
          padding: 0 25px;
        }
        @media(min-width: 1400px){
          padding: 0;
        }
        .item {
          aspect-ratio: 4/6;
          @media (min-width: $breakpoint-md-min) {
            aspect-ratio: 4/5.5;
          }
          @media (min-width: $breakpoint-lg-max) {
            aspect-ratio: 4/5;
          }

          position: relative;
          &:after {
            content: '';
            background: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 90%,
              rgba(255, 255, 255, 1) 100%
            );
            height: 70px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
          h4 a:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
          }
        }
      }
    }
    .slick-slide > div {
      padding: 0 1rem;
    }
    .slick-prev,
    .slick-next {
      width: 30px;
    }
    .slick-next {
      right: 5px;
    }
    .slick-prev {
      left: 5px;
    }
    @media(min-width: 1400px){
      .slick-prev,
      .slick-next {
        width: 50px;
      }
      .slick-next {
        right: -40px;
      }
      .slick-prev {
        left: -40px;
      }
    }
  }
}

.user_mode {
  .news-cards-top {
    @media (min-width: $breakpoint-lg-max) {
      padding-bottom: 0.7rem;
      .cta-title-dash {
        .pageElement {
          h3 {
            transform: translateY(5px);
          }
        }
      }
    }
  }
}
.user_mode {
  .news-cards-bottom {
    padding-bottom: 4.5rem;
    @media (min-width: $breakpoint-lg-max) {
      padding-bottom: 5.5rem;
    }
    .link-btn-ghost-small .linkElement h4 {
      display: flex;
      justify-content: center;
    }
  }
}
