.sn-media-slider {
  padding: 0;
  .slider-pagination {
    height: 18px;
    .paging-item {
      background-color: $color-white;
      transition: background-color $transition-ease;
      box-shadow: 0 0 5px 0px rgba(black, 0.2);
      &.flex-active {
        background-color: $color-secondary;
      }
      &:hover {
        background-color: $color-secondary;
      }
    }

  }
  .slider,
  .slider.sn-breakpoint-lt-500,
  .slider.sn-breakpoint-lt-300 {
    .slide-title {
      font-family: $font-primary;
      font-size: unquote('clamp(1.8rem, 2.5vw + 1rem, 4.8rem)');
      margin: 1% 0;
    }
    .slide-description {
      font-size: unquote('clamp(1.4rem, .7vw + 1rem, 2.4rem)');
      line-height: 1.2;
      margin: 1% 0;
    }
  }
  .slider {
    &.bottom-left-paging,
    &.bottom-center-paging,
    &.bottom-right-paging {
      margin-bottom: 0;
      .slider-pagination,
      .slider-pagination-numbers {
        bottom: 0;
        @media (min-width: $breakpoint-lg-max) {
          bottom: 4%;
        }
      }
    }
  }
  .slider-pagination {
    &.dashed {
      display: flex;
      justify-content: center;
      .paging-item {
        flex-basis: 100%;
        max-width: 70px;
        height: 4px;
        margin: 0 4px;
        &:before {
          // increases the target area for dashes
          content: '';
          width: 100%;
          height: 20px;
          display: block;
          position: relative;
          top: -8px;
          border: 1px solid rgba(black, 0);
        }
      }
    }
  }
  .slides .slide .slide-overlay {
    padding: 5% 10%;
    @media (min-width: $breakpoint-md-min) {
      padding: 5% 15%;
    }
  }
}

.add-overlay {
  .sn-media-slider .slides .slide .slide-overlay {
    background: $overlay-color;
  }
}
.add-overlay-gradient {
  .sn-media-slider .slides .slide .slide-overlay {
    background: $overlay-gradient;
  }
}


.user_mode .hide-slider-pager  {
  .sn-media-slider {
    .slider-pagination {
      display: none!important;
    }
  }
}
