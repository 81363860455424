/* Single Image Element */

.heroPhotoElement {
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .cutline {
    margin: 0;
    width: 100%;
  }
}

.image-sm {
  .heroPhotoElement {
    float: none;
    margin: 0 auto!important;
    padding:0!important;
    max-width: 125px;
  }
}

.image-md {
  .heroPhotoElement {
    float: none;
    margin: 0 auto!important;
    padding:0!important;
    max-width: 225px;
  }
}

.image-lg {
  .heroPhotoElement {
    float: none;
    margin: 0 auto!important;
    padding:0!important;
    max-width: 400px;
  }
}
