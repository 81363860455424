.user_mode {
  .layoutContainer.layout-50-50.cta-50-50 {
    display: flex;
    @media (min-width: $breakpoint-lg-max) {
      flex-wrap: nowrap;
      border-top: 4px solid white;
      border-bottom: 4px solid white;
    }
    gap: 4px;

    .column, .col-md-6 {
      min-width: 100%;
      @media (min-width: $breakpoint-lg-max) {
        min-width: 50%;
      }
      width: auto !important;
      aspect-ratio: 3/2;
      overflow: hidden;
      padding: 0;
      margin: 0;
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.6) 100%);
        z-index: 1;
      }
    }

    .heroPhotoElement {
      position: relative;
      padding: 0;
      margin: 0;
    }
    .textBlockElement {
      position: absolute;
      display: block;
      bottom: 5%;
      left: 0;
      right: 0;
      padding: 0 3.5rem;
      z-index: 2;
      h3 {
        margin-bottom: 0;
      }
    }
  }
}
