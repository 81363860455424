.user_mode {
  .hero-media-slider {
    .sn-media-slider {
      .flex-direction-nav .flex-prev {
        opacity: 0.4;
        @media(max-width: 1024px){
          left: calc(50% - 100vw/2 + 20px);
        }
        
        &:before {
          font-size: 4.8rem;
          color: white;
          text-shadow: none;
        }
      }
      .flex-direction-nav .flex-next {
        opacity: 0.4;
        @media(max-width: 1024px){
          right: calc(50% - 100vw/2 + 20px);
        }
        &:before {
          font-size: 4.8rem;
          color: white;
          text-shadow: none;
        }
      }

      &:hover {
        .flex-direction-nav .flex-prev {
          opacity: 0.4;
            left: 2%; 
          &:hover {
            opacity: 0.6;        
          }
        }
        .flex-direction-nav .flex-next {
          opacity: 0.4;
          right: 2%;
          &:hover {
            opacity: 0.6;
          }
        }
      }
      .slides .slide {
        .slide-overlay {
          display: grid !important;
          padding: 0 0 5% 0 !important;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.6) 100%);
          @media (min-width: 768px) {
            padding-bottom: 10% !important;
          }
        }
        img {
          transition: transform 10s ease;
        }

        &.flex-active-slide img {
          transform: scale(1.1);
        }
        &.flex-active-slide .slide-text.middle-left {
          transform: translateX(0);
        }
      }

      .slider {
        .slide-title {
          font-size: unquote('clamp(2rem, 4vw + 1rem, 4.2rem)');
          line-height: 1.14;
          text-shadow: none;
        }
        .slide-description {
          font-size: unquote('clamp(1.6rem, 1vw + 1rem, 1.8rem)');
          font-weight: 500;
          line-height: 1.33;
          text-shadow: none;
        }

        .slide-text.bottom-left {
          display: block;
          height: unset !important;
          margin: auto auto 0 auto;
          width: 100%;
          //max-width: 1310px;
          color: white;
          padding: 0 10% 2%;
          //   vertical-align: middle;
          //   text-align: left;
          //   transform: translateX(100px);
          //   transition: transform 1s ease;
          //   transition-delay: 0.5s;
        }
      }
      .slider-pagination {
        display: none;
        justify-content: flex-start;
        padding-left: 10%;
        padding-right: 10%;
        bottom: 20% !important;

        @media (min-width: $breakpoint-lg-max) {
          display: flex;
        }
        
        .paging-item {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          font-size: 1.2rem;
          @media(min-width: 1200px){
            font-size: 1.4rem;
            line-height: 1.3;
          }
          color: rgba(255, 255, 255, 0.5);
          height: 100px;
          padding: 10px 20px 0 0;
          margin: 0;
          text-align: left;
          max-width: 242px;
          width: 20%;
          background: transparent;
          box-shadow: none;
          //transition: color 2s linear;
          position: relative;
          &:before {
            display: none;
          }
          // &:after {
          //   content: '';
          //   background-color: #ffffff;
          //   width: 0;
          //   height: 4px;
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   transition: width 6s linear, opacity 0.2s linear;
          //   opacity: 0;
          // }
          &.flex-active {
            color: rgba(255, 255, 255, 1);

            // &:after {
            //   width: 100%;
            //   opacity: 1;
            // }
            .circle-off {
              stroke: var(--color-primary);
            }
            .circle-on {
              animation: circle-stroke 30s ease-out forwards;
            }

            &:before {
              display: none;
            }
          }
          svg {
            order: 1;
            position: absolute;
            top: -10px;
            left: -2px;
            //opacity: 0.4;
          }
          .circle-on {
            stroke-dasharray: 100;
            stroke-dashoffset: 100;
          }
          .circle-off {
            stroke: #999;
          }
        }
      }
    }
  }
}
.flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 2%;
}

@keyframes circle-stroke {
  to {
    stroke-dashoffset: 0;
  }
}

.user_mode .hero-slider {
  @media (max-width: $breakpoint-md-max) {
    overflow: hidden;
    .column-1 {
      min-height: 46.5rem;
      aspect-ratio: 21/9;
      width: unset;
      max-width: unset;
      .hero-media-slider {
        .sn-media-slider {
          .slider {
            .slides {
              .slide {
                .slide-overlay {
                  place-items: center;
                  padding: 0 0 3% 0 !important;
                  .slide-text.bottom-left {
                    max-width: 80vw;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
