.edit_mode {
  .layoutContainer.image-bg,
  .layoutContainer.hero-video {
    .pageEl.image-bg .heroPhotoElement {
      img {
        max-height: 300px;
        max-width: 100%;
        width: auto;
      }
      &:after {
        content: '[ Background Image ]';
        color: white;
        font-size: 24px;
        font-weight: 800;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: grid;
        place-items: center;
        text-shadow: 1px 1px 10px #322727, -1px -1px 10px black;
      }
    }
  }
}

.user_mode {
  .layoutContainer .pageEl.image-bg {
    display: none;
  }
  .hero-video .pageEl.image-bg {
    display: none;
  }
}
