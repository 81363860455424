// Root Variables

:root {
  // Primary Color
  --color-primary:hsl(48, 100%, 50%);
  --color-primary-base: 48, 100%; 
  --color-primary-lum: 50%;
  --color-primary: hsl(var(--color-primary-base), var(--color-primary-lum));
  --color-primary-dark-5: hsl(var(--color-primary-base), calc(var(--color-primary-lum) - 5%));
  --color-primary-dark-10: hsl(var(--color-primary-base), calc(var(--color-primary-lum) - 10%));
  --color-primary-dark-15: hsl(var(--color-primary-base), calc(var(--color-primary-lum) - 15%));
  --color-primary-dark-20: hsl(var(--color-primary-base), calc(var(--color-primary-lum) - 20%));
  --color-primary-light-5: hsl(var(--color-primary-base), calc(var(--color-primary-lum) + 5%));
  --color-primary-light-10: hsl(var(--color-primary-base), calc(var(--color-primary-lum) + 10%));
  --color-primary-light-15: hsl(var(--color-primary-base), calc(var(--color-primary-lum) + 15%));
  --color-primary-light-20: hsl(var(--color-primary-base), calc(var(--color-primary-lum) + 20%));

  // Secondary Color hsl(205, 97%, 15%);
  --color-secondary-base: 0, 0%;
  --color-secondary-lum: 7%;
  --color-secondary: hsl(var(--color-secondary-base), var(--color-secondary-lum));
  --color-secondary-dark-5: hsl(var(--color-secondary-base), calc(var(--color-secondary-lum) - 5%));
  --color-secondary-dark-10: hsl(var(--color-secondary-base), calc(var(--color-secondary-lum) - 10%));
  --color-secondary-dark-15: hsl(var(--color-secondary-base), calc(var(--color-secondary-lum) - 15%));
  --color-secondary-dark-20: hsl(var(--color-secondary-base), calc(var(--color-secondary-lum) - 20%));
  --color-secondary-light-5: hsl(var(--color-secondary-base), calc(var(--color-secondary-lum) + 5%));
  --color-secondary-light-10: hsl(var(--color-secondary-base), calc(var(--color-secondary-lum) + 10%));
  --color-secondary-light-15: hsl(var(--color-secondary-base), calc(var(--color-secondary-lum) + 15%));
  --color-secondary-light-20: hsl(var(--color-secondary-base), calc(var(--color-secondary-lum) + 20%));

  // Misc Colors
  --color-black: #000;
  --color-gray-dk: #333;
  --color-gray-md: #666;
  --color-gray: #ccc;
  --color-gray-lt: #eee;
  --color-white: #fff;
  --color-a: #aaa;
  --color-b: #bbb;
  --color-c: #ccc;
  --color-d: #ddd;
  --color-e: #eee;
  --color-hover-black: #000;
  --color-hover-gray-dk: #333;
  --color-hover-gray-md: #666;
  --color-hover-gray: #ccc;
  --color-hover-gray-lt: #eee;
  --color-hover-white: #fff;
  --color-hover-a: #aaa;
  --color-hover-b: #bbb;
  --color-hover-c: #ccc;
  --color-hover-d: #ddd;
  --color-hover-e: #eee;

  // Nav Items
  --main-nav-bg-color: #{$color-secondary};
  --main-nav-text-color: var(--color-primary);
  --main-nav-text-color-hover: white;
  --main-nav-text-color-selected: var(--main-nav-textcolor-hover);
  --main-nav-text-color-alt: hsl(0, 0%, 60%);
  --drop-text-color: var(--color-secondary);
  --drop-text-color-hover: var(--color-primary);
  --subnav-bg-color: black;
  --subnav-text-color: white;
  --subnav-text-color-hover: var(--color-primary);

  // Text Blocks
  --text-button-color: white;
  --text-button-bg-color: var(--color-primary);
  --text-button-bg-color-hover: var(--color-secondary);
  --text-button-border-radius: 5px;

  // Link Elements
  --link-color: white;
  --link-bg-color: var(--color-primary);
  --link-bg-color-hover: var(--color-secondary);

  // Sliders
  --overlay-color: rgba(0, 0, 0, 0.5);
  --overlay-gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
  --paging-color: var(--color-secondary-light-10);
  --paging-color-hover: var(--color-primary-light-10);
  --paging-color-active: var(--color-primary);

  // Aspect Ratios
  --asr-1_1: 1 / 1; // 1
  --asr-2_1: 2 / 1; // .5
  --asr-3_1: 3 / 1; // .333
  --asr-3_2: 3 / 2; // .666..7
  --asr-4_1: 4 / 1; // .25
  --asr-4_3: 4 / 3; // .75
  --asr-5_1: 5 / 1; // .2
  --asr-5_4: 5 / 3; // .6
  --asr-7_5: 7 / 5; // .7142857
  --asr-8_5: 8 / 5; // .625
  --asr-16_9: 16 / 9; // .5625
  --asr-c_1: 10 / 1; // .1 - use for custom aspect ratios
}
