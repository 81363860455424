.user_mode {
  .hero-media-slider-info-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: transparent;
    //border-bottom: 10px solid black;
    padding: 0;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    z-index: 5;

    .column {
      display: flex;
      flex-flow: column;
      justify-content: center;
      color: white;
      background-color: black;
      padding-top: 10px;
      white-space: nowrap;
      max-width: 100%;
      position: relative;

      a {
        font-size: 1.4rem;
        line-height: 4;
        color: white;
        text-transform: uppercase;
        transition: color $transition-linear;
        &:hover {
          color: var(--color-primary);
          text-decoration: none;
        }
      }
    }
    .pageEl,
    .pageElement {
      padding: 0;
      margin: 0;
    }
    .pageEl {
      display: grid;
      align-items: center;
    }
    .countdownElement {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      background: transparent;
      h2 {
        font-size: 1.8rem;
        color: white;
        white-space: normal;
        padding: 0 !important;
        margin: 20px 0 10px;
      }
      b {
        font-size: 3rem;
        color: white !important;
      }
      &:before,
      &:after {
        display: none;
      }
      small {
        display: none;
      }
      abbr {
        // display: flex;
        // flex-flow: row nowrap;
        // justify-content: center;
        // align-items: center;
      }
      abbr > span {
        display: inline;
        flex-grow: 0;
        margin: 0;
      }
      abbr > span > b {
        &:after {
          color: white;
          content: ':';
          padding: 0 3px;
        }
      }
      abbr > span:last-child > b {
        &:after {
          content: '';
        }
      }
    }
  }
}

@media (min-width: $breakpoint-lg-max) {
  .user_mode {
    .hero-media-slider-info-bar {
      justify-content: center;

      .column {
        flex-flow: row nowrap;
        align-items: center;
        color: white;
        padding-top: 0;
      }

      .pageEl {
        justify-content: center;
      }
      .countdownElement {
        display: flex;
        flex-flow: row nowrap;
        h2 {
          margin: 0;
          white-space: nowrap;
          &:before {
            content: '';
            display: block;
            height: 1px;
            background-color: #979797;
            width: 40px;
            transform: translate(-50px, 10px);
          }
        }

        abbr {
          display: flex;
          flex-flow: row nowrap;
          min-width: unset;
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .user_mode {
    .hero-media-slider-info-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: transparent;
      border-bottom: 10px solid black;
      padding: 0;
      margin: 0;
      max-width: 100%;
      overflow: hidden;
      margin-top: -45px;

      .column {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        color: white;
        background-color: black;
        min-height: 45px;
        max-height: 45px;
        padding-top: 10px;
        white-space: nowrap;
        max-width: 70%;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: -100px;
          bottom: 0;
          width: 100px;
          background: linear-gradient(
            145deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 1) 51%,
            rgba(0, 0, 0, 1) 100%
          );
        }
        a {
          font-size: 1.2rem;
          color: white;
          transition: color $transition-linear;
          &:hover {
            color: var(--color-primary);
            text-decoration: none;
          }
        }
      }
      .pageEl,
      .pageElement {
        padding: 0;
        margin: 0;
      }
      .pageEl {
        display: grid;
        align-items: center;
      }
      .countdownElement {
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        background: transparent;
        h2 {
          font-size: 1.8rem;
          color: white;
          padding: 0 !important;
          &:before {
            content: '';
            display: block;
            height: 1px;
            background-color: #979797;
            width: 40px;
            transform: translate(-10px, 10px);
          }
        }
        b {
          font-size: 3rem;
          color: white !important;
        }
        &:before,
        &:after {
          display: none;
        }
        small {
          display: none;
        }
        abbr > span {
          margin: 0;
        }
        abbr > span > b {
          &:after {
            color: white;
            content: ':';
            max-width: 14px;
            padding: 0 3px;
          }
        }
        abbr > span:last-child > b {
          &:after {
            content: '';
          }
        }
      }
    }
  }
}

@media (min-width: 1600px) {
  .user_mode {
    .hero-media-slider-info-bar {
      .column {
        max-width: 60%;
      }
    }
  }
}
