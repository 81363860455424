// Text Block Specific Styles
.pageEl .textBlockElement {
  table {
    border-style: solid;
    border-color: #999;

    caption {
      text-align: center;
      font-size: 1.4rem;
      padding: 5px;
      font-weight: bold;
    }

    td,
    th {
      padding: 2px 12px;
    }

    &:not([border]) {
      border-style: none;
      border-spacing: 2px;
      border-collapse: separate;

      th {
        @include table-th;
        @include table-th-text;
      }

      td {
        @include table-td;
        font-size: 1.2rem;
        background: #eee;
      }
    }

    &[align='center'] {
      margin-left: auto;
      margin-right: auto;
    }
  }

  img {
    width: auto;
    max-width: 100%;
    display: block;
    margin: auto;
  }

  .leftTextImage,
  .originalTextImage,
  .rightTextImage {
    br {
      display: none;
    }

    .cutline {
      width: 100%;
      margin: 0 auto;
    }
  }

  p {
    img {
      height: auto !important;
      max-width: 100%;
    }
  }
  .text {
    h3 {
      line-height: 1.2;
      margin-bottom: 0;
    }
    // Lists
    ul {
      padding-inline-start: 40px;
      //padding-left: 20px;
      // li > p:first-child {
      //   display: inline;
      // }
    }
    ol {
      padding-inline-start: 40px;
      // padding-left: 20px;
    }
    a.button {
      display: inline-block;
      color: $text-button-color;
      background-color: $text-button-bg-color;
      border-radius: $text-button-border-radius;
      padding: 1rem 1.5rem;
      margin: 1rem .5rem;
      transition: background-color $transition-ease;
      &:hover {
        background-color: $text-button-bg-color-hover;
        text-decoration: none;
      }
    }
  }
}
