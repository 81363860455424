$enable-global-font-smoothing: true !default;

$core-font-family: $font-secondary !default;
$core-font-size: 10px !default;
$core-line-height: 1.5 !default;
$core-font-weight: 400 !default;
$core-font-color: #444 !default;

$core-paragraph-font-size: 1.6rem !default;
$core-paragraph-margin-bottom: 1rem !default;

$core-headers-font: $font-primary !default;
$core-headers-line-height: 1.25 !default;

$core-link-color: $color-primary !default;
$core-table-font-size: 1.2rem !default;

// Global Type Styles >>> WARNING! These will affect ALL UI elements
@if ($enable-global-font-smoothing == true) {
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Body
html {
  font-size: 62.5%;
}

html,
body {
  font-family: $core-font-family;
  line-height: $core-line-height;
  font-weight: $core-font-weight;
  color: $core-font-color;
}
body {
  font-size: $core-font-size;
  text-rendering: optimizeLegibility;
  &.edit_mode {
    font-size: 16px;
  } // for WebKit. FF is on by default. IE? probably doesn't support it.
}

// Paragraphs
p {
  //font-size: $core-paragraph-font-size;
  font-size: $core-font-size; // needs to be specific in em for ckeditor
  margin-bottom: $core-paragraph-margin-bottom;
  line-height: 1.5;
}
// The cke_editable styles are for the ckeditor editor
// which doesn't support rem units and makes editibale 
// text sizes match the rendered versions 
.cke_editable {
  font-size: 16px;
  body {
    font-size: 16px;
  }
  p {
    font-size: 16px !important;
  }
  h1 {
    font-size: 42px !important;
  }
  h2 {
    font-size: 36px !important;
  }
  h3 {
    font-size: 30px !important;
  }
  h4 {
    font-size: 24px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  h6 {
    font-size: 16px !important;
  }
}

// Responsive Text & Headers
@media (min-width: 1024px) {
  p {
    font-size: 1.6rem;
  }
  h1 {
    font-size: 4.2rem;
  }
  h2 {
    font-size: 3.6rem;
  }
  h3 {
    font-size: 3rem;
  }
  h4 {
    font-size: 2.4rem;
  }
  h5 {
    font-size: 1.8rem;
  }
  h6 {
    font-size: 1.6rem;
  }
}
// Lists
ul {
  list-style-type: disc;
  padding-inline-start: 40px;
}

ol {
  padding-inline-start: 40px;
}

// Inline
b,
strong {
  font-weight: bold;
}
i,
em {
  font-style: italic;
}
small {
  font-size: 0.75em;
}
mark {
  background-color: yellow;
}
s,
strike,
del {
  text-decoration: line-through;
}
u {
  text-decoration: underline;
}
code {
  padding: 2px 4px;
  font-size: 0.75em;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

// Blockquotes
blockquote {
  $border-color: $global-ui-border-color;
  $border-width: 10px;
  $offset-width: $border-width + 10px;
  font-family: $font-primary;
  font-size: 20px;
  line-height: 1.4;
  font-style: italic;
  color: $core-font-color;
  box-shadow: inset $border-width 0px $border-color;
  padding-left: $offset-width;
  padding-top: 5px;
  padding-bottom: 5px;
  p {
    display: inline;
  } // Revisit when liquid
}

// Code/Pre-formatted
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}
pre {
  display: block;
  padding: 0.5em 1em;
  margin: 0 0 1rem 0;
  font-size: 0.75em;
  color: $core-font-color;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  background-color: #f5f5f5;
  border: 1px solid $global-ui-border-color;
  border-radius: 4px;
}

// Rules
hr {
  background-color: $global-ui-border-color;
  height: $global-ui-border-size;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
}

// Anchors
a {
  color: $core-link-color;
  text-decoration: none;
}

// Tables
table {
  font-size: $core-table-font-size;
}
