// Page Title (display Body Header)
$page-title-full-width: false !default;
$page-title-full-width-text: false !default;
$page-title-container-background: $content-area-background !default;
$page-title-text-color: #333 !default;
$page-title-font-size: 30px !default;
$page-title-text-case: none !default;
$page-title-font-weight: normal !default;
$page-title-text-align: null !default;

#displayBodyHeader {
  background-color: $page-title-container-background;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  min-height: 360px;
  //@if $page-title-full-width != true { @include content-area-width; }
  //@include page-title-container;
  span {
    position:relative;
    display: block;
    color: $page-title-text-color;
    font-size: $page-title-font-size;
    line-height: 1;
    text-transform: $page-title-text-case;
    font-weight: $page-title-font-weight;
    font-style: $page-title-font-style;
    text-align: $page-title-text-align;
    padding-bottom: 3rem;
    padding-left: 2rem;
    z-index: 1;
    opacity: 0;
    @media ($breakpoint-lg-max) {
      padding-left: 0;
    }
    @if $page-title-full-width-text != true and $page-title-full-width == true {
      @include content-area-width;
    }
    //@include page-element-padding;
    //@include content-container-padding(2);
    //@include page-title-text;
  }
  &.displayBodyHeader-image {
    height: auto !important;
    padding-top: 0px !important;
    background-position: center !important;
    //padding-bottom: clamp(1.5vw,10vw,17.5rem);
    //@include page-title-image-container;
  }
  &:before {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 50%, rgba(0, 0, 0, 0.6) 100%);
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
}
