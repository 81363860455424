.theme-main-nav .custom-main-nav-extra {
  transform: translateY(-4px);
  .global-nav-social-icons {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    .sn-social-media-icon {
      font-family: FontAwesome;
      font-size: 1.4rem;
      line-height: 2.5rem;
      display: block;
      border: 1px solid white;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      position: relative;
      transition: background-color $transition-ease, border-color $transition-ease;
      &:hover {
        border-color: var(--color-primary);
        background-color: var(--color-primary);
        
        // &:after {
        //   color: white;
        // }
      }
      &:after {
        content: '';
        color: white;
        display: grid;
        place-items: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: color $transition-ease;
      }
      &.icon-facebook:after {
        content: '\f09a';
      }
      &.icon-twitter:after {
        content: '\f099';
      }
      &.icon-instagram:after {
        content: '\f16d';
      }
      &.icon-youtube:after {
        content: '\f16a';
      }
    }
  }
  a.global-nav-button {
    font-size: 1.1rem;
    font-weight: 500;
    color: black;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    padding: 0 1.5rem;
    border-radius: 12px;
    line-height: 2.5rem;
    transition: color $transition-ease, background-color $transition-ease, border-color $transition-ease;
    &:hover {
      color: white;
      background-color: $color-secondary;
      border-color: white;
    }
  }
}
