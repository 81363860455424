.user_mode .layoutContainer.video-bg {
  padding: 4rem 0;
  background-position: center;
  color: white;

  > .video-bg .codeElement video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .pageElement {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: white;
    }
  }
  .column {
    position: relative;
    min-height: 1px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    z-index: 10;
  }

  &:before {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1;
  }
  @media screen and (max-width: 1023px) {
    &.has-bg-image {
      video {
        display: none;
      }
    }
  }
}
