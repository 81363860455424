.user_mode {
  .featured-races-cards {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    @media(min-width: 600px){
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media(min-width: 650px){
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @media(min-width: 768px){
      padding-left: 3rem;
      padding-right: 3rem;
    }
    @media(min-width: 1400px){
      padding-left: calc((100% - 1240px) / 2);
      padding-right: calc((100% - 1240px) / 2);
    }
    .slick-prev,
    .slick-next {
      width: 30px;
    }
    .slick-next {
      right: -20px;
    }
    .slick-prev {
      left: -20px;
    }
    @media(min-width: 1400px){
      .slick-prev,
      .slick-next {
        width: 50px;
      }
      .slick-next {
        right: -40px;
      }
      .slick-prev {
        left: -40px;
      }
    }
    
  }
  .featured-races-cards .column,
  .race-search-results .column {
    padding: 0 0 3rem 0;
    .race-card {
      display: inline-flex !important;
      flex-flow: column;
      position: relative;
      background-color: white;
      aspect-ratio: 1 / 1.25;
      @media(min-width: 1100px){
        aspect-ratio: 1 / 1.05;
      }
      width: 100%;
      //max-width: 430px;
      margin: auto;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.5s ease;
      &:hover {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        .race-photo {
          transform: scale(1.1);
        }
        .race-image:after {
          opacity: 0.5;
        }
      }

      .race-image {
        aspect-ratio: 3/2;
        overflow: hidden;
        border-bottom: 4px solid orange;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 1;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
          transition: opacity 0.5s ease;
        }
      }
      .race-photo {
        min-width: 100%;
        max-width: 100%;
        transition: transform 0.5s ease;
      }
      .race-logo {
        position: absolute;
        bottom: 2rem;
        left: 2rem;
        z-index: 1;
        img {
          width: 100%;
          height: auto;
          max-width: 48%;
          max-height: 190px;
        }
      }
      .race-info {
        display: flex;
        flex-flow: column;
        padding: 2rem;
        flex-grow: 1;
      }
      .race-name {
        font-size: 2.1rem;
        //white-space: nowrap;
        @media (min-width: 1005px) {
          font-size: unquote('clamp(1.5rem, 1vw + .5rem, 2.1rem)');
        }
        line-height: 1;
        padding: 0;
        margin: 0;
      }
      .race-dates {
        font-weight: 700;
      }
      .race-location {
        flex-grow: 1;
      }
      .race-features {
        display: flex;
        flex-flow: row nowrap;
        font-size: 1.1rem;
        font-weight: 700;
        
        @media (min-width: $breakpoint-sm-min) {
          font-size: 1.3rem;
        }
        @media (min-width: $breakpoint-md-min) {
          font-size: 1.4rem;
        }
        @media (min-width: $breakpoint-lg-max) {
          font-size: unquote('clamp(.6rem, 1vw + .2rem, 1.4rem)');
        }
        @media (min-width: $breakpoint-md-min) {
          margin-right: 0;
        }
        @media (min-width: $breakpoint-lg-max) {
          margin-right: unquote('clamp(0, 1vw, 3rem)');
        }
      }
      .race-feature {
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        white-space: nowrap;
        transform: translateX(-1rem);

        img {
          display: inline-block;
          max-height: 2rem;
          @media (min-width: $breakpoint-sm-min) {
            max-height: 3rem;
          }
          @media (min-width: $breakpoint-lg-max) {
            max-height: unquote('clamp(1rem, 1vw + 2.3rem, 4rem)');
          }
        }
      }
      .race-link {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }

    .slick-prev,
    .slick-next {
      &:before {
        color: #fff;
        opacity: 0.1;
        transition: opacity $transition-linear;
      }
      &:hover:before,
      &:focus:before {
        opacity: 1;
      }
    }
  }
}
.user_mode {
  .featured-races-bottom {
    padding-bottom: 4.5rem;
    @media (min-width: $breakpoint-lg-max) {
      padding-bottom: 5.5rem;
    }
    .link-btn-small .linkElement h4 {
      display: flex;
      justify-content: center;
    }
  }
}

.user_mode {
  .featured-races-cards {
    .column {
      padding: 0;
    }
    .slick-slide > div {
      padding: 0 1rem;
      //max-width: 66%;
    }
  }
}

.user_mode {
  .race-search-results .column {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem 0;
    @media (min-width: $breakpoint-lg-max) {
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }
    // .race-card {
    //   max-width: 390px;
    // }
  }
}

.user_mode {
  .no-results-found.layoutContainer {
    display: none;
    .no-results-found-image {
      width: 60%;
      max-width: 500px;
      margin: auto;
    }
  }
}

.edit_mode {
  .no-results-found-image {
    width: 60%;
    max-width: 500px;
    margin: auto;
  }
}
