// WYSIWYG Editor Typography Styles
// Ex: Text BLock Elements, News Article Previews, etc...
.pageEl .textBlockElement {
  .text,
  .previewText,
  .newsText {
    > :last-child {
      margin-bottom: 0;
    }

    // Lists
    > ul,
    > ol {
      margin-bottom: 1em;
    }
    // ul,
    // ol {
    //   list-style-position: inside;
    //   padding-left: auto;
    //   ul,
    //   ol {
    //     margin-left: auto;
    //   }
    // }
    // ul {
    //   list-style: none !important;
    //   li:before {
    //     content: "\25CF";
    //     display: inline-block;
    //     height: 1.6rem;
    //     width: 1.6rem;
    //   }
    // }



    // Headers
    @include content-typography-headers;

    // Anchors
    @include content-typography-anchors;

    // Blockquote
    @include content-typography-blockquote;

    // Others
    @include content-typography-other;
  }
}
