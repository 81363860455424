// Config
// Global Layout
// Page Manager
// Page Title

// Colors
$color-primary: hsl(44, 88%, 48%);  // #E6AE0F
$color-secondary: #111111;   // #111111
$color-tertiary: #575a5c; // #565A5C
$color-titles: #222;
$color-black: #000;
$color-gray-dk: #666;
$color-gray-md: #aaa;
$color-gray: #ccc;
$color-gray-lt: #eee;
$color-white: #f1f1f1;

// Fonts
$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Montserrat', sans-serif;
$enable-global-font-smoothing: true;
$core-font-size: 1.3rem;
$core-font-color: $color-secondary;

// Transitions 
$transition-standard: 0.2s ease-in;
$transition-linear: 0.2s linear;
$transition-ease: 0.3s ease;

// Variables
$desktop-padding: 1.25rem;
$mobile-padding: .75rem;

$content-area-width: 124rem;
$content-area-padding: ($desktop-padding * 2);
$content-area-padding-mobile: ($mobile-padding * 2);
$content-area-background: #fff;

$content-container-margin: 3rem;
$enable-full-width-layout: true;
$include-page-title-in-content-area-background: true;

$breakpoint-sm-min: 375px;
$breakpoint-sm-max: 767px;
$breakpoint-md-min: 768px;
$breakpoint-md-max: 999px;
$breakpoint-lg-min: 1023px;
$breakpoint-lg-max: 1024px;
$breakpoint-xlg-min: 1025px;

// UI Elements
$global-ui-border-size: 0.1rem;
$global-ui-border-color: gainsboro;

// Utilities
$utility-padding-desktop: 4rem;
$utility-padding-mobile: 2rem;
$utility-padding-large-desktop: 7rem;
$utility-padding-large-mobile: 4rem;
$utility-padding-small-desktop: 2rem;
$utility-padding-small-mobile: 1rem;

// Global Layout
@mixin content-width() {
  padding-left: $mobile-padding;
  padding-right: $mobile-padding;
  @media (min-width:1240px) {
    padding-left: calc((100% - #{$content-area-width}) / 2);
    padding-right: calc((100% - #{$content-area-width}) / 2);
  }
}
@mixin adjust-margin-x($multiplier: 1, $responsive: true) {
  margin-left: $desktop-padding * $multiplier;
  margin-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max) {
      margin-left: $mobile-padding * $multiplier;
      margin-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin page-element-padding() {
  padding-top: $desktop-padding;
  padding-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max) {
    padding-top: $mobile-padding;
    padding-bottom: $mobile-padding;
  }
}
@mixin page-element-margin() {
  margin-top: $desktop-padding;
  margin-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max) {
    margin-top: $mobile-padding;
    margin-bottom: $mobile-padding;
  }
}
@mixin column-padding() {
  padding-left: $desktop-padding;
  padding-right: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max) {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}
@mixin content-container-padding($multiplier: 1, $responsive: true) {
  padding-left: $desktop-padding * $multiplier;
  padding-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-left: $mobile-padding * $multiplier;
      padding-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-container-padding-vert($multiplier: 1, $responsive: true) {
  padding-top: $desktop-padding * $multiplier;
  padding-bottom: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-top: $mobile-padding * $multiplier;
      padding-bottom: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-area-width {
  width: 100%;
  max-width: $content-area-width;
  margin-left: auto;
  margin-right: auto;
}
@mixin content-area-container-style {
  background-color: $content-area-background;
  box-shadow: 0px 2px 9px rgba(black, 0.05);
}
@mixin global-link-style {
  background-color: $color-secondary;
  transition: background-color $transition-linear, transform $transition-linear;
  //-webkit-appearance: none;
  text-decoration: none;
  color: #fff;
  &:focus,
  &:hover {
    background-color: var(--color-primary);
  }
}
@mixin global-link-container($padding: 1.2rem 1.5rem) {
  display: block;
  padding: $padding;
}
@mixin global-link-text($align: left) {
  //text-transform: uppercase;
  font-family: $font-primary;
  font-size: 1.6rem;
  line-height: 1.3;
  font-weight: 500;
  text-align: $align;
}
@mixin global-link-icon-style($align: flex-start, $color: $color-white, $hover: #fff) {
  display: flex;
  flex-flow: row;
  justify-content: $align;
  align-items: center;
  &:after {
    color: $color;
    display: inline-block;
    margin-left: 1ch;
    transform: translateY(-2px);
    transition: color $transition-linear;
    position:absolute;
    right: 1.5rem;
  }
  &:hover:after {
    color: $hover;
  }
}
@mixin global-link-icon-character($character: '\f105', $font: FontAwesome) {
  &:after {
    content: $character;
    font-family: $font;
    font-size: 2.4rem;
  }
}
@mixin link-button (){
  font-size: 1.4rem;
  font-weight: 700!important;
  color: black;
  background-color: rgba(var(--color-primary),0);
  padding: 1.5rem 3.5rem;
  border: 1px solid black;
  display: inline-block;
  transition: border $transition-linear, color $transition-linear, background-color $transition-linear;
  &:hover {
    color: white;
    background-color: rgba(var(--color-primary),1);
    border: 1px solid var(--color-primary);
    text-indent: 0;
    text-decoration: none;
  }
  &:after {
    display: none;
  }
}
@mixin link-button-small (){
  font-size: 1.4rem;
  font-weight: 700!important;
  color: black;
  background-color: rgba($color-primary,0);
  padding: .7rem 2.5rem;
  border: 1px solid black;
  display: inline-block;
  transition: border $transition-linear, color $transition-linear, background-color $transition-linear;
  &:hover {
    color: white;
    background-color: rgba(var(--color-primary),1);
    border: 1px solid var(--color-primary);
    text-indent: 0;
    text-decoration: none;
  }
  &:after {
    display: none;
  }
}
@mixin link-button-ghost-small (){
  font-size: 1.4rem;
  font-weight: 700!important;
  color: black;
  background-color: rgba(var(--color-primary),0);
  padding: .7rem 2.5rem;
  border: 1px solid black;
  display: inline-block;
  transition: border $transition-linear, color $transition-linear, background-color $transition-linear;
  &:hover {
    color: white;
    background-color: rgba(var(--color-primary),1);
    border: 1px solid white;
    text-indent: 0;
    text-decoration: none;
  }
  &:after {
    display: none;
  }
}

// Page Manager
$page-manager-width: 6rem;

// Page Title
$page-title-full-width: false;
$page-title-full-width-text: false;
$page-title-container-background: $content-area-background;
$page-title-text-color: white;
$page-title-font-size: unquote('clamp(2.4rem, 2.5vw + 1rem, 3.6rem)');
$page-title-text-case: uppercase;
$page-title-font-weight: bold;
$page-title-font-style: italic;
$page-title-text-align: null;

@mixin page-title-container {
  // site page title container css here
}
@mixin page-title-text {
  //border-bottom: $global-ui-border-size solid $global-ui-border-color;
  // site page title text css here
}
@mixin page-title-image-container {
  // site page title w/image container css here
}

// Account Navigation Options
$account-nav-bottom: false;
$account-nav-mobile-bottom: true;
$account-nav-background: #23282b;
$account-nav-color: #fff;
$account-nav-secondary-color: $account-nav-color;
$account-nav-secondary-color-hover: darken($account-nav-color, 20%);
$account-nav-font-size: 1.4rem;
$account-nav-height-desktop: 4rem;
$account-nav-height-mobile: 4.4rem;
$account-nav-z-index: 399;
$account-nav-icon-width: 3.2rem;

$site-tagline-spacing: 1.5rem; // Need this for "_source/sass/_navigation/_network_nav_bar.scss" —— Try to phase this out

// General Navigation
$pages-hidden-in-user-mode: 'home'; // Example: "teams", "about us", "home";

// Title Bar Options
$nav-title-bar-height: 0;
$nav-title-bar-height-fixed: 0.001rem;
$nav-title-bar-band-background-color: transparent;
$nav-title-bar-text-color: white;
$nav-title-bar-font-family: null;
$nav-title-bar-font-size: 1.4rem;
$nav-title-bar-line-height: 1;
$nav-title-bar-text-case: uppercase;
$nav-title-bar-text-weight: 700;

// Main Navigation Options
$main-nav-width-max: $content-area-width;
$main-nav-height: 3rem;
$main-nav-height-fixed: 3rem;
$main-nav-band-background-color: transparent;
$main-nav-flex-alignment: flex-start;
$main-nav-item-padding: 1.5rem;

$main-nav-cascade-styles: true;
$main-nav-child-indicator: false;
$main-nav-child-indicator-cascade-styles: false;
$main-nav-more-plus-buffer: true;
$main-nav-font-family: $font-primary;
$main-nav-font-size: 1.4rem;
$main-nav-text-case: uppercase;
$main-nav-text-align: center;
$main-nav-text-weight: 700;
$main-nav-text-color: white;
$main-nav-text-color-hover: white;
$main-nav-text-color-selected: $main-nav-text-color-hover;
$main-nav-text-color-disabled: hsl(0, 0%, 60%);
$main-nav-item-background-color: transparent;
$main-nav-item-background-color-hover: rgba(#ddd, 0);
$main-nav-item-background-color-selected: $main-nav-item-background-color-hover;
$main-nav-item-background-color-disabled: rgba($color-white, 0);

// Dropdown Navigation Options
$dropdown-nav-min-width: 20rem;
$dropdown-nav-min-height: 3.5rem;
$dropdown-nav-transition-distance: 2rem;
$dropdown-nav-item-padding: 2rem;
$dropdown-nav-fixed-margin-top: 3.5rem;

$dropdown-nav-cascade-styles: true;
$dropdown-nav-child-indicator: true;
$dropdown-nav-child-indicator-cascade-styles: false;
$dropdown-nav-container-background-color: rgba(#f5f5f5, 0.9);
$dropdown-nav-container-border-size: 0;
$dropdown-nav-container-border: $dropdown-nav-container-border-size solid rgba($color-secondary, 0.7);
$dropdown-nav-text-align: left;
$dropdown-nav-text-case: none;
$dropdown-nav-text-weight: normal;
$dropdown-nav-font-family: null;
$dropdown-nav-font-size: 1.4rem;
$dropdown-nav-font-height: 1.6rem;
$dropdown-nav-line-height: 1.2;
$dropdown-nav-text-color: black;
$dropdown-nav-text-color-hover: $color-primary;
$dropdown-nav-text-color-selected: $dropdown-nav-text-color-hover;
$dropdown-nav-item-background-color: transparent;
$dropdown-nav-item-background-color-hover: #fcfcfc;
$dropdown-nav-item-background-color-selected: $dropdown-nav-item-background-color-hover;
$dropdown-nav-item-background-color-disabled: #eee;

$dropdown-nav-2-container-background-color: $dropdown-nav-container-background-color;
$dropdown-nav-2-container-border-size: null;
$dropdown-nav-2-container-border: null;
$dropdown-nav-2-text-align: null;
$dropdown-nav-2-text-case: null;
$dropdown-nav-2-text-weight: null;
$dropdown-nav-2-font-family: null;
$dropdown-nav-2-font-size: null;
$dropdown-nav-2-line-height: null;
$dropdown-nav-2-text-color: null;
$dropdown-nav-2-text-color-hover: null;
$dropdown-nav-2-text-color-selected: null;
$dropdown-nav-2-text-color-disabled: null;
$dropdown-nav-2-item-background-color: null;
$dropdown-nav-2-item-background-color-hover: null;
$dropdown-nav-2-item-background-color-selected: null;
$dropdown-nav-2-item-background-color-disabled: null;

// Sub Navigation Options
$sub-nav-hide-on-home: true;
$sub-nav-more-plus-buffer: true;
$sub-nav-font-family: null;
$sub-nav-font-size: 1.4rem;
$sub-nav-font-height: 1.6rem;
$sub-nav-text-color: $color-tertiary;
$sub-nav-text-color-hover: $color-secondary;
$sub-nav-text-color-disabled: rgba($color-black, 0.4);
$sub-nav-item-background-color-disabled: rgba($color-white, 0.1);
$sub-nav-band-background-color: white;
$sub-nav-height: 6rem;
$sub-nav-height-fixed: 5rem;
$sub-nav-item-background-color: transparent;
$sub-nav-item-background-color-hover: transparent;
$sub-nav-item-text-case: none;
$sub-nav-item-text-weight: 700;
$sub-nav-item-padding: 2rem;
$sub-nav-flex-alignment: flex-start;

// Sub Navigation Title Options
$sub-nav-title-font-family: null;
$sub-nav-title-font-size: $sub-nav-font-size;
$sub-nav-title-height: $sub-nav-height;
$sub-nav-title-text-case: uppercase;
$sub-nav-title-text-weight: bold;
$sub-nav-title-background-color: transparent;
$sub-nav-title-text-color: $color-tertiary;
$sub-nav-title-padding: 2rem;
$sub-nav-title-divider-content: '\f105';
$sub-nav-title-divider-font: 'fontawesome';

// Sub Navigation Dropdown Options
$sub-nav-dropdown-padding: $sub-nav-item-padding;
$sub-nav-dropdown-font-family: null;
$sub-nav-dropdown-font-size: 1.4rem;
$sub-nav-dropdown-font-height: 1.6rem;
$sub-nav-dropdown-text-case: none;
$sub-nav-dropdown-line-height: 1.2;
$sub-nav-dropdown-min-width: 20rem;
$sub-nav-dropdown-min-height: 3.5rem;
$sub-nav-dropdown-transition-distance: 2rem;
$sub-nav-dropdown-container-background-color: $sub-nav-band-background-color;
$sub-nav-dropdown-container-border: 0;
$sub-nav-dropdown-text-align: left;

// Search (Desktop) Options
$site-search-height: 2.6rem;
$site-search-spacing: 0.8rem;
$site-search-border-width: 1rem;
$site-search-input-font-size: 1.4rem;
$site-search-input-color: #666;
$site-search-input-border-color: rgba(255, 255, 255, 0.1);
$site-search-input-background: rgba(255, 255, 255, 0.8);
$site-search-input-background-focus: rgba(255, 255, 255, 1);
$site-search-submit-color: #fff;
$site-search-submit-border-color: rgba(255, 255, 255, 0.1);
$site-search-submit-background: darken($color-primary, 10%);
$site-search-submit-background-focus: darken($color-primary, 20%);
$site-search-submit-font-size: 1.2rem;

// Desktop Navigation Sizes
$desktop-nav-height: $nav-title-bar-height + $main-nav-height + $sub-nav-height;
$desktop-nav-height-fixed: $nav-title-bar-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed;
$desktop-nav-height-no-subnav: $nav-title-bar-height + $main-nav-height;
$desktop-nav-height-no-subnav-fixed: $nav-title-bar-height-fixed + $main-nav-height-fixed;
$nav-placeholder-height: $desktop-nav-height; // Remove after replacing these in code base
$nav-placeholder-height-fixed: $desktop-nav-height-fixed; // Remove after replacing these in code base

// Site Logo Options
$site-logo-size: 21.5rem; // Remove after replacing these in code base
$site-logo-size-fixed: 21.5rem; // Remove after replacing these in code base
$site-logo-width-desktop: $site-logo-size;
$site-logo-height-desktop: 10.5rem;
$site-logo-width-desktop-fixed: $site-logo-size-fixed;
$site-logo-height-desktop-fixed: $site-logo-height-desktop;
$site-logo-width-mobile: 12rem;
$site-logo-height-mobile: 4rem;
$site-logo-padding: 0.5rem;
$site-logo-offset: $site-logo-width-desktop;
$site-logo-offset-fixed: $site-logo-width-desktop-fixed;

// Mobile Nav Bar
$mobile-nav-background-color: #000;
$mobile-nav-toggle-right: true;
$mobile-nav-toggle-width: 4.4rem;
$mobile-nav-toggle-height: 4.4rem;
$mobile-nav-toggle-position: center;
$mobile-nav-open-toggle-position-default: true;
$mobile-nav-height: 7rem;
$mobile-nav-align: center;
$mobile-nav-justification: left;

// Footer Colophon
$footer-colophon-se-logo-black-color: false;
$footer-colophon-se-logo-white-color: true;
$footer-colophon-se-logo-white: false;
$footer-colophon-max-width: $content-area-width;
$footer-colophon-band-background-color: rgba(0,0,0,.1);
$footer-colophon-content-background-color: transparent;
$footer-colophon-item-padding: 1rem;
$footer-colophon-flex-justification: center;
$footer-colophon-flex-align: center;
$footer-colophon-font-size: 1.2rem;
$footer-colophon-line-height: 1.5;
$footer-colophon-text-case: none;
$footer-colophon-text-color: white;
$footer-colophon-link-color: white;
$footer-colophon-link-color-hover: $color-primary;

// Site Background
$site-background-image-full-height: false;

// Page Banner
$site-banner-full-width: true;
$site-banner-full-width-text: false;

$site-banner-padding: 1.5rem;
$site-banner-fixed-min-height: 10rem;
$site-banner-font-size: 2rem;
$site-banner-font-size-min: 2rem;
$site-banner-font-size-max: 7.2rem;
$site-banner-text-color: white;
$site-banner-font-family: null;
$site-banner-line-height: 1;
$site-banner-font-weight: normal;
$site-banner-text-case: null;
$site-banner-text-align: center;

@mixin site-banner-container {
  // site banner container css here
}

$site-banner-headline-text-color: inherit;
$site-banner-headline-font-size: null;
$site-banner-headline-font-family: null;
$site-banner-headline-line-height: null;
$site-banner-headline-font-weight: null;
$site-banner-headline-text-case: null;
$site-banner-headline-text-align: null;
$site-banner-title-text-color: inherit;
$site-banner-title-font-size: inherit;
$site-banner-title-font-family: null;
$site-banner-title-line-height: inherit;
$site-banner-title-font-weight: inherit;
$site-banner-title-text-case: null;
$site-banner-title-text-align: null;
$site-banner-tagline-text-color: inherit;
$site-banner-tagline-font-size: null;
$site-banner-tagline-font-family: null;
$site-banner-tagline-line-height: null;
$site-banner-tagline-font-weight: null;
$site-banner-tagline-text-case: null;
$site-banner-tagline-text-align: null;

// Element Options
$element-options-flex-justification: flex-end;
$element-options-font-size: 1.2rem;
$element-options-text-wieght: 600;
$element-options-text-color: #333;
$element-options-text-color-hover: $color-primary;
$element-options-font-family: $font-secondary;
$element-options-icon-margin: 0.7ch;
$element-options-icon-text-space: 1.5ch;

// Tabs
$element-tabs-item-padding: 1.6rem 2.5rem;
$element-tabs-item-margin: 0 .2rem 0 0;
$element-tabs-item-text-color: $color-tertiary;
$element-tabs-item-text-color-hover: white;
$element-tabs-item-background-color: null;
$element-tabs-item-background-color-hover: $color-tertiary;
$element-tabs-item-text-case: null;
$element-tabs-item-font-family: null;
$element-tabs-item-font-weight: 500;
$element-tabs-item-font-size: 1.4rem;
$element-tabs-item-text-line-height: 1.142;
$element-tabs-item-border-size: 0.1rem;
$element-tabs-item-border-position: bottom;
$element-tabs-item-border-color: #ddd;
$element-tabs-item-border-color-hover: $color-tertiary;

// Content Typography (WYSIWYG editor content)
@mixin content-typography-headers() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-primary;
    color: $color-titles;
    font-weight: 700;
    line-height: 1.25;
  }
  h1 {
    font-size: 3.6rem;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2.4rem;
  }
  h4 {
    font-size: 2rem;
    line-height: 1.4;
  }
  h5 {
    font-size: 1.5rem;
    line-height: 1.9;
  }
  h6 {
    font-size: 1.4rem;
    line-height: 1.9;
  }

}
// Page Element Header
@mixin page-element-header() {
  font-family: $font-primary;
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
  color: $color-titles;
  @media (min-width: $breakpoint-lg-max){
    font-size: 2.1rem;  
  }
}

@mixin content-typography-anchors() {
  a {
    color: $color-primary;
    font-family: $font-primary;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin content-typography-blockquote() {
  blockquote {
    font-weight: 400;
    font-size: 2.4rem;
    color: $color-secondary;
  }
}

@mixin content-typography-other() {
  // catchall for anything else you need
}

// Text Blocks
$text-button-color: white;
$text-button-bg-color: $color-primary;
$text-button-bg-color-hover: $color-secondary;
$text-button-border-radius: 5px;

// Link Elements
$link-color: white;
$link-bg-color: $color-primary;
$link-bg-color-hover: $color-secondary;

// Legacy Itasca Table Variables
// Used elsewhere: Calendar, event aggs, etc...
$table-body-background: #fff; // legacy, not used in base tables
$table-cell-spacing: 0.1rem; // legacy, not used in base tables

// Core Table Padding (needs a bit more refactoring)
$core-table-header-padding-vertical: 0.3rem;
$core-table-header-padding-horizontal: 1.2rem;
$core-table-cell-padding-vertical: 1.4rem;
$core-table-cell-padding-horizontal: 1.2rem;

// Core Table Borders
$core-table-cell-border-spacing: 0 0.2rem;
$core-table-cell-border-size: 0;
$core-table-cell-border-color: $color-gray-lt;
$core-table-cell-border-width: 0;
$core-table-cell-border: 0 $core-table-cell-border-width $core-table-cell-border-width 0;
$core-table-border-left-width: $core-table-cell-border-width;
$core-table-border-right-width: $core-table-cell-border-width;
$core-table-border-top-width: $core-table-cell-border-width;
$core-table-border-bottom-width: $core-table-cell-border-width;

// Core Table Body Typography
$core-table-body-font-family: null;
$core-table-body-font-size: 1.4rem;
$core-table-body-line-height: 1.2;
$core-table-body-font-weight: 400;
$core-table-body-font-color: null;

// Core Table Body Layout
$core-table-body-min-height: 4rem;
$core-table-body-background-color: #f7f7f7;
$core-table-body-background-color-odd: $core-table-body-background-color;
$core-table-body-background-color-even: #f1f1f1;
$core-table-body-hightlight-color-odd: $color-gray;
$core-table-body-hightlight-color-even: $color-gray;

// Core Table Header Typography
$core-table-header-font-family: $font-primary;
$core-table-header-font-size: 1.4rem;
$core-table-header-line-height: 1.2;
$core-table-header-font-weight: 700;
$core-table-header-font-color: white;

// Core Table Header Layout
$core-table-header-min-height: 5rem;
$core-table-header-background-color: $color-secondary;
$core-table-header-hightlight-color: darken($core-table-header-background-color, 5%);

// Core Table Sorting
$core-table-sort-hover-color: rgba($core-table-header-font-color, 0.7);
$core-table-sort-icon-default: '\f0dc';
$core-table-sort-icon-up: '\f0de';
$core-table-sort-icon-down: '\f0dd';
$core-table-sort-icon-size: $core-table-header-font-size;
$core-table-sort-icon-padding: $core-table-sort-icon-size + 0.4rem;
$core-table-sort-icon-offset: $core-table-header-padding-horizontal + $core-table-sort-icon-padding;

// Sliders
$overlay-color: rgba(0, 0, 0, 0.5);
$overlay-gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);

// Mixins/Functions
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@function aspect-to-percent($target, $container) {
  @return ($container / $target) * 100%;
}

.hide-in-user-mode {
  .user_mode & {
    display: none!important;
  }
}
