.user_mode {
  .accordion-container {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    .accordion-container-title {
      flex-basis: 100%;
      margin: 0;
      padding: 0 15px;
      cursor: pointer;
      h3 {
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
        line-height: 48px;
        &:after {
          content: '\f107';
          font-family: fontawesome;
          padding: 0 10px;
          transition: transform $transition-ease;
        }
      }
    }
    &.active {
      .accordion-container-title h3:after {
        transform: rotate(180deg);
      }
    }
    .column {
        max-height: 0;
        transition: max-height 0.5s ease-in-out;
      }
  }

}
