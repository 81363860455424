.qualifier-guidelines {
  padding: 0;

  .heroPhotoElement {
    max-width: 50%;
    margin: 0 auto;
    float: none;
  }

  .textBlockElement {
    & > h3 {
      color: var(--color-primary);
      font-weight: 900;
      text-transform: uppercase;
      font-style: italic;
      margin-top: 2rem;
    }
    .text {
      line-height: 2;
      h4 {
        color: $color-tertiary;
        font-weight: 800;
        margin-bottom: 2rem;
      }
      p {
        line-height: 2;
      }
    }
  }

  @media (min-width: $breakpoint-md-min) {
    .column-1 {
      max-width: 65%;
    }

    .heroPhotoElement {
      max-width: 40%;
    }
  }

  @media (min-width: $breakpoint-lg-max) {
    .heroPhotoElement {
      max-width: 30%;
    }
  }
  @media (min-width: $breakpoint-xlg-min) {
    .textBlockElement {
      & > h3 {
        font-size: 3.6rem;
      }
      .text {
        h4 {
          font-size: 2.4rem;
        }
      }
    }
    .heroPhotoElement {
      max-width: 20%;
    }
  }
}
