.user_mode.header_image #panelTwo {
  position: relative;
  padding-top: 60px;
  @media (min-width: $breakpoint-lg-max) {
    padding-top: 75px;
  }
}

ul.lng-select {
  background: black;
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  top: 0;
  height: 30px;
  right: 20px;
  @media (min-width: $breakpoint-xlg-min) {
    //right: calc((100% - 1240px) / 2);
    right: 20px;
  }
  @media (min-width: 1260px) {
    //right: calc((100% - 1240px) / 2);
  }
  cursor: pointer;
  z-index: 400;
  li {
    font-size: 1.1rem;
    color: white;
    font-weight: 700;
    line-height: 20px;
    background: black;
    padding: 5px 25px 5px 15px;
    margin: 0;
    text-transform: uppercase;
    position: relative;
    li:hover {
      background: #333;
    }
    &.selected {
      background: black;
      &:after {
        content: '\f107';
        font-family: 'fontawesome';
        position: absolute;
        display: grid;
        place-items: center;
        right: 10px;
        top: 0;
        bottom: 0;
      }
    }
    &:nth-child(n + 2) {
      background: #333;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity 0.2s linear, max-height 0.2s linear;
    }
  }
  &.active {
    li {
      opacity: 1;
      max-height: 30px;
    }
  }
}
.nav-fixed ul.lng-select {
  z-index: 1;
}
.slide-nav-open ul.lng-select {
  z-index: auto;
}
