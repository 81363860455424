.race-features.layoutContainer {
  background-color: var(--color-primary);
  padding-top: 2rem;
  padding-bottom: 2rem;
  .race-features {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    list-style-type: none;
    color: white;
    padding: 0 1.5rem;
    li {
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 50%;
      max-width: 50%;
      text-align: center;
      padding: 2rem 1rem;
      @media ($breakpoint-lg-max){
        padding: 1rem;
      }
      
    }
    .icon {
      color: black;
      text-align: center;
      background: none;
      padding: 0;
      img {
        max-height: 3rem;
        height: 3rem;
        margin: 0 auto;
        filter: brightness(0) invert(1);
      }
    }
    div.text {
      display: flex;
      flex-flow: column;
      justify-content: center;
      text-align: center;
      // white-space: nowrap;
      span:first-child {
        font-weight: 800;
        font-size: 18px;
        line-height: 1.214;
      }
      span:last-child {
        color: #aaa;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2.57px;
      }
    }
  }
}

@media (min-width: $breakpoint-lg-max) {
  .race-features.layoutContainer {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    .column {
      padding: 0;
    }
    .race-features {
      padding: 0;
      flex-flow: row nowrap;
      li {
        flex-flow: row;
        &.location .icon {
          //transform: translateX(1rem);
        }
        &.days .icon {
          //transform: translateX(-1rem);
        }
        &.km .icon {
          //transform: translateX(-1rem);
        }
        &.meters .icon {
          //transform: translateX(-1rem);
        }
      }
      .icon {
        margin-bottom: 0;
        padding-right: 2rem;
        img {
          max-height: 4.8rem;
          height: 4.8rem;
        }
      }
      div.text {
        text-align: left;
        white-space: nowrap;
        span:first-child {
          font-size: clamp(1rem, 1.8vw, 2.8rem);
        }
        span:last-child {
          font-size: 12px;
          letter-spacing: 2.57px;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .race-features.layoutContainer {
    .race-features {
      div.text {
        span:first-child {
          font-size: 2.8rem;
        }
      }
    }
  }
}
