.qualify-3-column {
  .column-1 {
    margin-bottom: 1rem;
    @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-lg-min) {
      width: 100%;
    }
    @media (min-width: $breakpoint-lg-max) {
      margin-bottom: 0;
    }
    .textBlockElement {
      & > h3 {
        color: var(--color-primary);
        font-weight: 800;
        text-transform: uppercase;
        font-style: italic;
        @media (min-width: $breakpoint-lg-max) {
          font-size: 3.6rem;
        }
      }
    }
  }
  .column-2,
  .column-3 {
    display: grid;
    place-items: center;
    padding: 2rem 1rem;
    color: white;
    background-color: black;
    margin: 1rem 0;
    @media (min-width: $breakpoint-md-min) {
      width: 100%;
      //margin: 0 1rem;
      //aspect-ratio: unset;
    }
    @media (min-width: $breakpoint-lg-max) {
      width: calc((100% / 3) - 2rem);
      padding: 2rem 2rem;
      margin: 0 1rem;
      aspect-ratio: unset;
    }

    .textBlockElement {
      & > h3 {
        color: white;
        text-transform: uppercase;
        font-style: italic;
      }
    }
  }
}
