// Page Elements

.pageElement {
  font-size: 1.6rem;
  width: 100%;
  @include page-element-margin;
}
.pageEl {
  @extend %clearfix;
  > .pageElement {
    float: left;
    clear: both;
  }
}

// Eliminate this if possible
.pageElement {
  .pageElement {
    margin-left: 0;
    margin-right: 0;
  }
}

.edit_mode {
  .tool-item {
    font-size: 1.2rem;
  }
}
