.user_mode {
  .layoutContainer {
    &[class*='cta-text-'] {
      display: block;

      .column {
        position: absolute;
        left: 5%;
        bottom: 4%;
        max-width: 82%;
        @media (min-width: $breakpoint-lg-max) {
          left: 10%;
          bottom: 7%;
          max-width: 40%;
        }
      }
    }
    &[class*='cta-text-right'] {
      .column {
        @media (min-width: $breakpoint-lg-max) {
          left: 55%;
        }
      }
    }
    &.cta-title-color-primary,
    &.cta-title-color-primary.text-color-light {
      .textBlockElement > h3,
      .textBlockElement .text h3 {
        color: $color-primary;
      }
    }
  }
}

.cta-logo {
  .heroPhotoElement {
    padding: 0;
    margin: 0;
    img {
      height: auto;
      width: auto;
      max-width: 62%;
      max-height: 71px;
      margin: 0;
      @media (min-width: $breakpoint-lg-max) {
        max-width: 294px;
        max-height: 158px;
      }
    }
  }

}
