$player-search-controls-margin: 0.5rem;

body.race-search .site-background {
  background-color:#f1f1f1;
}

.user_mode .search-container {
  .column {
    padding:0;
  }
  font-size: 1.6rem;
  position: relative;
  padding-bottom: 0;
  #search-form {
    &.open {
      display: flex;
    }
    display: none;
    @media (min-width: $breakpoint-lg-max) {
      display: flex;
    }
    flex-flow: column;
    @media (min-width: $breakpoint-lg-max) {
      flex-flow: row wrap;
    }
    //justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent;
    border-radius: 10px;
    @media (min-width: $breakpoint-lg-max) {
      //height: 9rem;
      background-color: #ffffff;
    }
    width: 100%;
    margin: 0 auto 1px;
    z-index: 100;
    .form-el {
      padding: 0 0.5rem;
    }
    .pad-left-x {
      padding-left: 0;
    }
    .pad-right-x {
      padding-right: 0;
    }
    button,
    input,
    select,
    textarea {
      font-family: inherit;
      font-size: 100%;
    }
    input {
      border: 1px solid $color-secondary;
      padding: 0 10px;
      font-size: 11px;
      font-weight: bold;
      line-height: 3.5rem;
      border-radius: 5px;
      color: $color-secondary;
      width: 100%;
      max-width: calc(100% - 6rem);
      margin: 0 0 0 3rem;
      @media (min-width: $breakpoint-lg-max) {
        max-width: 37rem;
        margin: 0 0 0 3rem;
      }
      &:focus {
        outline: none;
        border-color: $color-secondary;
      }
    }
    > * {
      margin-bottom: $player-search-controls-margin;
    }

    .keyword-search-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      position: relative;
      background: white;
      width: 100%;
      margin: 1px 0 0 0;
      padding: 2.5rem 0;
      @media (min-width: $breakpoint-lg-max) {
        max-width: 35%;
      }
    }
    .btn-search {
      border: none;
      background-color: transparent;
      padding: 0 1rem;
      margin: 0 0 0 auto;
      transform: translateX(-4rem);
      @media (min-width: $breakpoint-lg-max) {
        margin: 0;
        transform: translateX(-4rem);
      }
      display: inline-block;
      font-size: 1.6rem;
      line-height: 3.5rem;
      cursor: pointer;

      &:before {
        content: '\f002';
        font-family: fontawesome;
        font-size: 1.3rem;
        color: $color-secondary;
        //margin-right: 8px;
      }
      &:hover {
        //background-color: black;
      }
    }
    #clear-button-wrapper {
      position: relative;
      background: white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 auto;
      padding: 1rem 0;
      border-radius: 0 0 10px 10px;
      @media (min-width: $breakpoint-lg-max) {
        border-radius: 0;
        max-width: 10%;
      }
    }

    .select-list {
      &#duration-select, &#country-select {
        z-index: 30;
      }
      &#month-select, &#sort-order-select {
        z-index: 20;
      }
      &#region-select {
        z-index: 10;
      }

      list-style-type: none;
      line-height: 3rem;
      padding: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
      margin: 1px 0 0 0;
      height: 7rem;
      max-height: 7rem;
      width: 100%;
      @media (min-width: $breakpoint-lg-max) {
        border-right: 1px solid #f5f5f5;
        margin-top: 0;
        max-width: 18%;
        &#country-select {
          max-width: 23%;
        }
      }
      //min-width: 20rem;
      cursor: pointer;
      position: relative;

      // &:after {
      //   content: '';
      //   background: #f5f5f5;
      //   max-width: 1px;
      // }

      li {
        display: flex;
        align-items: center;
        max-height: 3rem;
        height: 3rem;
        max-height: 0;
        padding: 0 3rem;
        overflow: hidden;
        opacity: 1;
        background-color: white;
        transition: max-height 0.3s ease, opacity 0.3s ease, color 0.5s ease, background-color 0.3s ease;
        &.placeholder {
          font-size: 1.8rem;
          color: black;
          height: 7rem;
          max-height: 7rem;
          // padding-left: 1rem;
          // padding-right: 3.5rem;
          opacity: 1;
          overflow: hidden;
        }
        &.selected {
          color: black;
        }
        &:last-child {
          border-radius: 0 0 10px 10px;
        }
      }
      //&:hover,
      &.open {
        li:not(.placeholder) {
          max-height: 3rem;
          box-shadow: 2px 4px 2px 0 rgba(0, 0, 0, 0.2);
          &:not(.selected) {
            //background-color: rgba(225, 225, 225, 0.9);
            //max-height: 3rem;
            opacity: 1;
            color: $color-tertiary;
            // &:hover {
            //   background-color: #ddd;
            // }
          }
        }
        li:last-child {
          height: 4rem;
          max-height: 4.5rem;
          padding-top: 0;
          padding-bottom: 1rem;
        }
        &:after {
          transform: rotate(180deg);
        }
      }
      &.closed {
        li:not(.selected) {
          max-height: 0;
        }
      }
      &:after {
        content: '\f107';
        font-family: fontawesome;
        font-size: 2.5rem;
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 3rem;
        height: 3rem;
        text-align: center;
        transition: transform 0.3s ease;
        pointer-events: none;
      }
      // &.has-selection {
      //   li:after {
      //     content: '×';
      //     font-size: 3rem;
      //     position: absolute;
      //     top: 0;
      //     right: 2.5rem;
      //     width: 3rem;
      //     height: 3rem;
      //     text-align: center;
      //     transition: transform 0.3s ease;
      //     pointer-events: none;
      //   }
      // }
    }

    .btn-clear {
      white-space: nowrap;
      background: transparent;
      border: 0;
      padding: 2rem 0;
      margin: 0 3rem 0 3rem;
      cursor: pointer;
    }
  }
  #filters-toggle-mobile {
    display: block;
    width: 100%;
    padding: 4rem 2rem 2rem 2rem;
    margin: 0;
    border-radius: 10px;
    background: white url(//assets.ngin.com/site_files/59392/_stage/_site/images/epic/filters-toggle.png) center no-repeat;
    @media (min-width: $breakpoint-lg-max) {
      display: none;
    }
    &.open {
      border-radius: 10px 10px 0 0;
    }
  }
  #selection-crumbs {
    background: white;
    padding: 2rem;
    margin: 0;
    border-radius: 5px;
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
    &:empty{
      display: none;
    }

    @media (min-width: $breakpoint-lg-max) {
      order: 1;
      flex-basis: 100%;
      text-align: left;
      border-top: 1px solid #f1f1f1;
    }
    color: #999;
    span {
      padding: 0 1rem;
    }
  }
  #search-counts {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0 0 0;
    font-size: 1.1rem;
    color: $color-secondary;
  }
}
