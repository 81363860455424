.user_mode {
  .partnerships-ads-2-col {
    display: grid;
    grid-template-columns: 1fr;

    .column {
      width: 100%;
    }
    @media (min-width: $breakpoint-lg-max) {
      grid-template-columns: 1fr 1fr;
      .column-1 {
        grid-column: span 2;
      }
    }
  }
}
