footer.snFooterContainer {
  background: linear-gradient(180deg, #565a5c 0%, #2c363b 99.05%);
  padding: 7rem 0 0;
}

.custom-footer-extra {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  @media (max-width: 1023px) {
    > * {
      grid-column: 1 / -1 !important;
      grid-row: auto !important;
    }
  }
  max-width: 1180px;
  margin: auto;

  .pageElement {
    padding: 0 2rem 1rem;
    text-align: center;
  }
  .pageElement > h3:first-child {
    font-size: 1.4rem;
    line-height: 1.66;
    font-weight: 800;
    font-style: normal;
    text-align: center !important;
    text-transform: uppercase;
    color: white;
    &:after {
      display: none;
    }
  }
  .textBlockElement .text {
    padding-bottom: 1rem;
    @media (min-width: 1024px) {
      padding-bottom: 4rem;
    }
  }
  p {
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 0;
  }
  a {
    color: #fff;
    transition: color $transition-linear;
    text-decoration: none;
    &:hover {
      color: $color-primary;
      text-decoration: none;
    }
  }
  .footer-logo-epic img {
    max-width: 200px;
  }

  .footer-social {
    grid-area: 2 / 1 / 2 / 3;
    border-top: 1px solid $color-primary;
    padding: 2rem 0 3rem;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    gap: 1rem;
    margin-top: 2rem;
    .sn-social-media-icon {
      font-family: FontAwesome;
      font-size: 1.8rem;
      line-height: 3.5rem;
      display: block;
      border: 1px solid white;
      background-color: rgba($color-primary, 0);
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      position: relative;
      transition: color $transition-ease, border-color $transition-ease, background-color $transition-ease;
      &:hover {
        border-color: $color-primary;
        background-color: rgba($color-primary, 1);
        &:after {
          color: white;
        }
      }
      &:after {
        content: '';
        color: white;
        display: grid;
        place-items: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: color $transition-ease;
      }
      &.icon-facebook:after {
        content: '\f09a';
      }
      &.icon-twitter:after {
        content: '\f099';
      }
      &.icon-instagram:after {
        content: '\f16d';
      }
      &.icon-youtube:after {
        content: '\f16a';
      }
    }
  }

  .footer-logo-im {
    display: flex;
    flex-flow: column;
    grid-area: 2 / 3 / 2 / 6;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 3rem;
    margin: 0;
    p.cutline {
      font-size: 12px;
      text-align: center;
      font-style: normal;
      color: white;
      line-height: 1;
      transform: translateY(5px);
      order: 1;
      background: transparent;
      padding: 0 1rem;
      margin: 0 0 1rem;
    }
    img {
      order: 2;
      max-height: 30px;
      width: auto;
    }
  }
  ul.footer-legal-links {
    display: flex;
    flex-flow: column;
    grid-column: 1/-1;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    list-style-type: none;
    width: 100%;
    padding: 3rem 0;
    margin: 0;
    a {
      text-decoration: none;
    }
  }
}

@media (min-width: 1024px) {

  .custom-footer-extra {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    padding: 0 3rem;

    .pageElement {
      text-align: left;
    }
    .pageElement > h3:first-child {
      text-align: left !important;
    }

    .footer-logo-epic {
      padding-left: 0;
      img {
        margin-right: auto;
      }
    }

    .footer-social {
      justify-content: flex-start;
      margin-top: 0;
    }

    .footer-logo-im {
      flex-flow: row nowrap;
      justify-content: start;
      border-top: 1px solid $color-primary;
      align-items: center;

      p.cutline {
        text-align: right;
      }
    }
    ul.footer-legal-links {
      flex-flow: row nowrap;
      gap: 1.5rem;
    }
  }
  #siteFooter > ul{
    flex-flow: row wrap;
  }
  #poweredByNGIN a {
    transform: translateY(-50%);
  }
}

@media (min-width: 1300px) {

  .custom-footer-extra {
    padding: 0;
  }
}