// Addtional utility classes to control flex columns on containers and whether the container has a title element as the first element. For containers with titles the title element should display as a single column

// Mobile flexs
@media (max-width: 768px) {
  .user_mode .layoutContainer {
    &.flex-1x-mbl {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: 100%;
          padding: 10px;
        }
      }
    }
    &.flex-2x-mbl {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: 50%;
          padding: 10px;
        }
      }
    }
    &.flex-3x-mbl {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: calc(100% / 3);
          padding: 10px;
        }
      }
    }
    &.flex-4x-mbl {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: 25%;
          padding: 10px;
        }
      }
    }
    &.flex-5x-mbl {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: 20%;
          padding: 10px;
        }
      }
    }
  }
}

// Desktop flexs
@media (min-width: 769px) {
  .user_mode .layoutContainer {
    &.flex-1x-dsk {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: 100%;
          padding: 20px;
        }
      }
    }
    &.flex-2x-dsk {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: 50%;
          padding: 20px;
        }
      }
    }
    &.flex-3x-dsk {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: calc(100% / 3);
          padding: 20px;
        }
      }
    }
    &.flex-4x-dsk {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: 25%;
          padding: 20px;
        }
      }
    }
    &.flex-5x-dsk {
      .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        .pageEl {
          flex-basis: 20%;
          padding: 20px;
        }
      }
    }
    &.has-ct-title {
      .column .pageEl:first-child {
        flex-basis: 100%;
      }
    }
  }
}

// Images in flexs - max sizes

.user_mode {
  .logo-flex-sz .heroPhotoElement {
    display: grid;
    place-items: center;
    height: 100%;
    margin: 0;
    img {
      max-width: 125px;
      max-height: 65px;
      @media (min-width: 1024px) {
        max-width: 200px;
        max-height: 100px;
      }
    }
  }
}
.user_mode {
    .flex-width-100 {
        flex-basis: 100% !important;

    }
}
.edit_mode {
  .logo-flex-sz .heroPhotoElement {
    display: grid;
    place-items: center;
    height: 100%;
    margin: 0;
    background-color: #eee;
    img {
      max-width: 200px;
      max-height: 100px;
    }
  }
}
