.user_mode {
  &.epic-race {
    .event-nav {
      display: none;
    }
  }
}

@media (min-width: 1025px) {
  .user_mode {
    &.epic-race {
      #sn-site-logo {
        display: none;
      }
      .theme-main-nav {
        padding-bottom: 1rem;
        .theme-nav {
          display: none;
        }
      }
      .event-nav {
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding: 0.5rem calc((100% - 1240px) / 2);
        background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
        position: relative;
        z-index: 500;
        .event-logo {
          color: transparent;
          font-size: 8px;
          min-width: 16%;
          min-height: 90px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: left center;
        }
        .global-date {
          font-weight: 700;
        }
        ul {
          display: flex;
          align-items: center;
          gap: 2rem;
          list-style-type: none;
          margin: 0;
          padding: 0 0 0 2rem;
          @media (min-width: 1100px) {
            gap: 3rem;
          }
          a {
            display: block;
            color: white;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 3rem;
            white-space: nowrap;
          }

          // ul ul li a {
          //   &:after {
          //     display: none;
          //   }
          // }
          & > li {
            position: relative;
            display: block;
            width: 100%;

            &:hover {
              & > ul:first-of-type {
                pointer-events: auto;
                opacity: 1;
                //display: flex;
                transform: translateY(0);
              }
            }
            // &:last-child {
            //   last nav item should be the same as the button link in the header
            //   hidden so there's only one visible link for this in the top nav
            //   display: none;
            // }
          }
        }
        ul ul {
          padding-left: 0;
        }
        & > ul > li {
          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            width: 0;
            bottom: 0;
            background-color: var(--color-primary);
            height: 3px;
            opacity: 0;
            transition: opacity 0.2s ease-in-out, width 0.2s ease-in-out, right 0.2s linear, left 0.2s linear;
          }
          &:hover:after {
            opacity: 1;
            width: 100%;
            left: 0;
            right: auto;
          }
        }
        //& > ul > li > a {
          // &:after {
          //   content: '';
          //   position: absolute;
          //   left: 0;
          //   right: 0;
          //   width: 0;
          //   bottom: 0;
          //   background-color: var(--color-primary);
          //   height: 3px;
          //   opacity: 0;
          //   transition: opacity 0.2s ease-in-out, width 0.2s ease-in-out, right 0.2s linear, left 0.2s linear;
          // }
          // &:hover:after {
          //   opacity: 1;
          //   width: 100%;
          //   left: 0;
          //   right: auto;
          // }
        //}
        ul li ul {
          background: rgba(245, 245, 245, 0.9);
          pointer-events: none;
          display: flex;
          opacity: 0;
          flex-flow: column;
          align-items: flex-start;
          gap: 0;
          position: absolute;
          left: 0;
          top: 3rem;
          min-width: 200px;
          transform: translateY(-20px);
          transition: transform 0.2s ease-in-out;
          a {
            color: black;
            font-weight: 400;
            text-transform: none;
            padding: .4rem 1.5rem;
            transition: color $transition-linear, background-color $transition-linear;
            &:hover {
              color: var(--color-primary);
              background-color:#fcfcfc;
            }
          }
        }
        .global-nav-social-icons {
          display: none;
          @media (min-width: 1220px) {
            display: flex;
          }
        }
      }
    }
  }
}
