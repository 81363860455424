.user_mode {
  .logo-grid-ct.layoutContainer {
    // padding-top: 1rem;
    // padding-bottom: 4rem;
    // @media (min-width: 1024px) {
    //   padding-bottom: 0;
    // }
    .column {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      @media (min-width: 1024px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      grid-gap: 2rem;
      place-items: center;
      padding: 0 3rem 0;
      .heroPhotoElement {
        max-width: 240px;
        padding-bottom: 1rem;
        @media (min-width: 1024px) {
          padding-top: 1rem;
          padding-bottom: 2rem;
        }
        img {
          max-height: 55px;
         // height: 100px;
          //width: auto;
          @media (min-width: 1024px) {
            max-height: 100px;
          }
        }
      }
      .pageEl:first-of-type {
        grid-area: 1 / 1 / 1 / 4;
        @media (min-width: 1024px) {
          grid-area: 1 / 1 / 1 / 5;
        }
      }
    }
  }
}

.edit_mode {
  .layoutContainer.logo-grid-ct {
    .pageEl:not(.image-bg) .heroPhotoElement {
      background: #ddd;
      padding: 5px;
      img {
        max-height: 100px;
        height: 100px;
        width: auto;
      }
    }
  }
}
