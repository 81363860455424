.user_mode .hero-slider {

    & > .column {
        padding: 0;
    }

    .pageElement {
        margin: 0;
        & > h2, & > p {
            display: none;
        }
    }

}