// Global Page Element Headers
.user_mode .pageElement {
  @include content-typography-headers();
}
.pageElement > h3:first-child {
  .cta-title-dash & {
    @include page-element-header;
    text-align: center !important;
    &:after {
      content: '';
      display: inline-block;
      background: #4d5052;
      top: -6px;
      left: 10px;
      width: 35px;
      height: 3px;
      position: relative;
    }

    @media (min-width: 1024px) {
      text-align: left !important;
      &:after {
        width: 65px;
      }
    }
  }
  .cta-title-large & {
    font-size: 2.4rem;
    @media (min-width: 1024px) {
      font-size: 3.6rem;
    }
  }
}
.pageElement > h3:only-child {
  margin-bottom: 0;
}

.pageEl.hide-dash {
  .pageElement > h3:first-child:after {
    display: none;
  }
}

.pageEl.dash-light {
  .pageElement > h3:first-child:after {
    background: #979797;
  }
}
