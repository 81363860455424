.user_mode {
  .videos-container {
    padding: 2rem 1rem 3rem;
    background-size: cover !important;
    @media (min-width: 1024px) {
      background-size: cover !important;
    }

    .column {
      max-width: 1200px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem 0;

      // .videos-container .column > * {
      //   grid-column: 1 / -1;
      //   grid-row: auto;
      // }
      .video-title {
        //padding-bottom: 2rem;
        .pageElement {
          margin: 0;
        }
        h3 {
          text-align: center !important;
        }
      }
      .video-button {
        margin: auto;
        order: 5;
        //padding-top: 1rem;
        //padding-bottom: 2rem;
      }
      .videoElement {
        margin: 0;
        //padding-bottom: 2rem;
        .video {
          aspect-ratio: 16 / 9;
        }
      }

      @media (min-width: 1024px) {
        padding: 4rem 3rem 6rem;
        grid-template-columns: repeat(2, 1.03fr) 1fr;
        gap: 0 20px;
        .video-title {
          grid-column: span 2;
          display: flex;
          align-items: flex-end;
          padding-bottom: 2rem;
          h3 {
            text-align: left !important;
          }
        }
        .video-button {
          width: 100%;
          display: grid;
          justify-content: flex-end;
          padding-bottom: 2rem;
        }
        .main-video {
          grid-area: 2 / 1 / 4 / 3;
          padding: 0;
        }
        .side-video-top {
          grid-area: 2 / 3 / 3 / 4;
          //max-width: 92%;
          //max-width: $video-size-clamp;
          //transform: translateX(6%);
          padding: 0;
        }
        .side-video-bottom {
          grid-area: 3 / 3 / 4 / 4;
          //max-width: 92%;
          //max-width: $video-size-clamp;
          align-self: flex-end;
          //transform: translateX(6%);
          padding: 0;
        }
      }
    }
  }
}

.edit_mode {
  .videos-container {
    .pageElement.videoElement {
      max-width: 30%;
    }
  }
}
