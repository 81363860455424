// Race page hamburger nav is a secondary nav for desktop mode only
// Appears only on race pages to provide links to the global pages (ie not race specific pages)
// It's always hidden in mobile so only the mobile nav is visible in mobile

.user_mode {
  .race-nav-title-bar {
    display: none;
  }

  ul.global-race-nav {
    display: none;
  }
}

.user_mode {
  @media (min-width: 1025px) {
    .race-nav-title-bar {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      max-height: 50px;
      width: 100%;
      padding: 0 calc((100% - 1240px) / 2);
      background: black;
      border-bottom: 1px solid rgba(51, 51, 51, 0.9);
      .global-nav--toggle {
        height: 100%;
        max-height: 50px;
        width: 100%;
        max-width: 40px;
        position: relative;
        cursor: pointer;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          background-image: url(../images/epic/hamburger-mobile.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 20px;
          height: 100%;
          display: block;
          background-position: center center;
        }

      }
      .race-nav-site-logo {
        display:block;
        height: 100%;
        width: 130px;
        .sn-site-logo-background {
          display: block;
          height: 100%;
          background-repeat: no-repeat;
          background-size: auto 32px;
          background-position: left center;
        }
      }
    }

    ul.global-race-nav {
      display: flex;
      flex-flow: column;
      list-style-type: none;
      background: rgba(17, 17, 17, 0.9);
      padding: 0;
      margin: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 380px;
      height: 100vh;
      z-index: 1000;
      transform: translateX(-380px);
      transition: transform 0.3s ease-in-out;

      &.open {
        transform: translateX(0);
      }
      li {
        border-bottom: 1px solid rgba(51, 51, 51, 0.9);
        a {
          display: block;
          color: white;
          font-size: 1.4rem;
          font-weight: bold;
          text-decoration: none;
          padding: 1.9rem 3rem;
        }
        &.global-nav--toggle {
          background: rgba(17, 17, 17, 1);
          a {
            padding: 1.5rem 3rem;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              display: block;
              top: 0;
              right: 3rem;
              width: 15px;
              height: 100%;
              background-image: url(../images/epic/hamburger-close-mobile.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
    }
  }
  &.logged_in {
    ul.global-race-nav {
      top: 60px;
      &.open{
        transform: translateX(60px);
      }
    }
  }
}
