//$news-agg-card-gap: 1rem;
$news-collector-cards-padding: 2rem;

.user_mode {
  .news-cards {
    .collectorNews,
    .newsAggregatorElement {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      gap: 3rem;
      font-size: 1.6rem;
      color: $color-secondary;
      font-weight: 400;
      line-height: 1.2;

      .item {
        flex-basis: 100%;
        min-width: calc(100% / 3 - 3rem);
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        background-color: white;
        padding: 0;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow $transition-linear;
        &:hover {
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
          & > a img {
            transform: scale(1.1);
          }
        }
        &.condensed,
        &.expanded {
          margin: 0;
          padding-bottom: 1rem;
          border: 0;
          border-radius: 1rem;
          overflow: hidden;
        }
        &.condensed > a {
          &:before,
          &:after {
            display: none;
          }
        }

        & > * {
          display: flex;
          flex-flow: column;
          justify-content: center;
          margin: 0;
          padding: 0;
        }

        & > a {
          // display: flex;
          // flex-flow: column;
          // justify-content: center;
          position: relative;
          background: #ddd;
          margin: 0;
          padding: 0;
          width: 100%;
          max-width: 100%;
          aspect-ratio: 3/2;
          overflow: hidden;
          transition: transform $transition-ease;
          border-bottom: 4px solid $color-primary;
          //&:hover {
          // img {
          //   transform: scale(1.1);
          // }
          //}

          img {
            height: 100%;
            object-fit: cover;
            transition: transform $transition-linear;
          }
        }

        & > h4 {
          a {
            font-size: 2.1rem;
            line-height: 1.238;
            color: $color-secondary;
            transition: color $transition-linear;
            padding: 0.8rem $news-collector-cards-padding 0.5rem;
            // &:hover {
            //   color: $color-primary;
            // }
          }
          & > h2 {
            padding: 1.5rem $news-collector-cards-padding;
          }
        }
        .article-tag {
          font-size: 1.2rem;
          color: $color-gray-md;
          font-weight: 700;
          letter-spacing: 3.27px;
          text-transform: uppercase;
          padding: 2rem $news-collector-cards-padding 0;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            border-bottom: 11px solid #fff;
            border-right: 8px solid transparent;
            height: 0;
            width: 50%;
          }
        }

        .dateAuthor {
          font-size: 1.4rem;
          margin-bottom: 1.5rem;
          padding: 0 $news-collector-cards-padding;
          .newsAuthor {
            font-size: 1.4rem;
          }
          .newsDate {
            font-size: 1.4rem;
          }
        }
        .teaser {
          display: flex;
          justify-content: flex-start;
          color: $color-tertiary;
          text-transform: none;
          flex-grow: 1;
          line-height: 1.3;
          margin-bottom: 1.5rem;
          padding: 0 $news-collector-cards-padding;
        }
        .tags {
          padding: 0 $news-collector-cards-padding;
        }
        .newsText {
          display: none;
        }
      }
      .bottomOptions,
      .extendedOptions {
        flex-basis: 100%;
      }

      // .newsAggregatorElement only
      h3.aggHeader {
        flex-basis: 100%;
      }
    }
  }

  @media (min-width: 768px) {
    .col-md-4 {
      .news-cards {
        .item {
          flex-basis: 100%;
        }
      }
    }
    .col-md-12 {
      .news-cards {
        .item {
          flex-basis: calc(100% / 2 - 2rem);
        }
      }
    }
  }
  @media (min-width: 1024px) {
    .col-md-8 {
      .news-cards {
        .item {
          flex-basis: calc(100% / 2 - 2rem);
        }
      }
    }
    .col-md-12 {
      .news-cards {
        .item {
          flex-basis: calc(100% / 4 - 2rem);
        }
      }
    }
  }
}


