// Works in conjuction with: _news_agg_refactor.js
// news-refactor to news agg custom classes

.user_mode .news-refactor .newsAggregatorElement {
  display: flex;
  flex-flow: column;
  font-size: 1.4rem;
  color: $color-secondary;
  font-weight: 400;

  .article {
    padding: 1.5rem;
    margin-bottom: 0.5rem;
    border: 0;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.07);
    transition: box-shadow $transition-linear;
    &:hover {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      .newsItemElement .article-photo img {
        transform: scale(1.1);
      }
    }
  }

  .newsItemElement {
    display: flex;
    flex-flow: column;
    position: relative;

    .article-photo-wrapper {
      flex-basis: 100%;
      aspect-ratio: 3/2;
      overflow: hidden;
    }
    .article-photo {
      display: block;
      img {
        max-width: 100%;
        transition: transform $transition-linear;
      }
    }
    .read-more {
      max-width: 15rem;
      text-align: center;
    }
    .article-text-wrapper {
      padding-top: 1rem;
    }
    .article-tag {
      font-size: 1.2rem;
      color: #aaa;
      font-weight: 700;
      letter-spacing: 3.27px;
      text-transform: uppercase;
      padding: 0 0 1.5rem;
      position: relative;
    }
    h4 {
      font-size: 1.8rem;
      line-height: 1.2;
      a {
        color: $color-secondary;
        transition: color $transition-linear;
        // &:hover {
        //   color: $color-primary;
        // }
      }
    }
    .teaser,
    .teaser p {
      font-size: 1.4rem;
    }
  }
  @media (min-width: 768px) {
    .newsItemElement {
      flex-flow: row;
      gap: 1.5rem;
      .article-photo-wrapper {
        flex-basis: calc(40% - 0.75rem);
      }
      .article-text-wrapper {
        flex-basis: calc(60% - 0.75rem);
        padding-top: 0;
      }
      &.no-photo {
        .article-photo-wrapper {
          display: none;
        }
        .article-text-wrapper {
          flex-basis: 100%;
        }
      }
    }
  }
}
.user_mode .layoutContainer {
  .col-md-4,
  .col-md-3,
  .col-md-6 {
    .newsAggregatorElement .newsItemElement {
      flex-flow: column;
    }
  }
  @media (min-width: 1024px) {
    .col-md-6 {
      .newsAggregatorElement .newsItemElement {
        flex-flow: row;
      }
    }
  }
}

.newsAggregatorElement {
  .user_mode .news-hide-date-author & {
    .dateAuthor {
      display: none;
    }
  }
  .user_mode .news-hide-author & {
    .newsAuthor {
      display: none;
    }
  }
  .user_mode .news-hide-date & {
    .newsDate {
      display: none;
    }
  }
  .user_mode .news-hide-teaser & {
    .teaser {
      display: none;
    }
    .article-text-wrapper p:not(.date-author) {
      display: none;
    }
  }
  .user_mode .news-hide-preview & {
    .newsText {
      display: none;
    }
  }
  .user_mode .news-hide-read-more & {
    .read-more {
      display: none;
    }
  }
  .user_mode .news-hide-options & {
    .extendedOptions {
      display: none;
    }
  }
  .user_mode .news-hide-image & {
    .article-photo-wrapper {
      display: none;
    }
  }
  .user_mode .news-hide-headline & {
    h4 {
      display: none;
    }
  }
}
