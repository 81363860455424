.user_mode {
  .layoutContainer.series-partners-logo-grid {
    .column {
      display: grid;
      grid-template-columns: 1fr;
      .pageEl {
        grid-column: span 1;
        display: grid;
        place-items: center;
      }
      .pageEl.cta-title-dash {
        grid-column: span 1;
        //order: 1;
      }
      .pageEl.link-btn-small {
        grid-column: span 1;
        order: 1;
        h4 {
          text-align: center;
        }
      }
      .pageEl .heroPhotoElement {
        max-width: 150px;
        aspect-ratio: 1 / 1;
        display: grid;
        place-items: center;
        transition: transform $transition-ease;
        &:hover{
          transform: scale(1.1);
        }
      }
      @media (min-width: $breakpoint-lg-max) {
        grid-template-columns: repeat(5, 1fr);
        .pageEl.cta-title-dash {
          grid-column: span 3;
        }
        .pageEl.link-btn-small {
            grid-column: span 2;
            order: unset;
            h4 {
                text-align: right;
              }
        }
      }
    }
  }
}

.edit_mode {
  .layoutContainer.series-partners-logo-grid {
    .pageEl:not(.image-bg) .heroPhotoElement {
      background: #ddd;
      padding: 5px;
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
}
